import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useMemo, useState } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import { Campaign } from 'models/MediaFileCampaign';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import { campaignLayoutDisplay } from 'enum/CampaignLayout';
import { screenOrientationDisplay } from 'enum/ScreenOrientation';
import Button from 'components/Button';
import PlayerCampaignPreview from 'components/Campaign/PlayerCampaignPreview';

function CampaignListPage() {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: campaignsData, isLoading } = useApiGet<Pagination<Campaign>>(
    '/media-manager/campaigns',
    {
      params: filteredData
    }
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/media-manager/campaigns/${id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const handleCampaignContinuosOnEnded = () => {
    setIsPreviewOpen(true);
  };

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Campanhas</span>
          </p>
        </Col>
        {userCanAdd && (
          <Col className="text-end">
            <Link
              to={'/media-manager/campaigns/create'}
              className="btn btn-primary"
            >
              <Icon icon="plus" />
              Adicionar Novo
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar campanha"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {campaignsData?.items.map((campaign) => (
            <tr>
              <div className="d-flex d-flex justify-content-between align-items-center">
                <div
                  className="d-flex justify-content-between align-items-center w-100"
                  key={campaign.id}
                  role="button"
                  onClick={() => goToEdit(campaign.id ?? '')}
                >
                  <td className="w-100">
                    <small>Nome: </small>
                    <br />
                    <b>{campaign.name}</b>
                  </td>
                  <td className="w-100">
                    <small>Duração: </small>
                    <br />
                    <b>
                      {campaign?.medias?.reduce(
                        (total, media) => total + (media?.duration || 0),
                        0
                      )}{' '}
                      segundos
                    </b>
                  </td>
                  <td className="w-100">
                    <small>Layout: </small>
                    <br />
                    <b>{campaignLayoutDisplay(campaign.layoutType)}</b>
                  </td>
                  <td className="w-100">
                    <small>Orientação: </small>
                    <br />
                    <b>
                      {screenOrientationDisplay(campaign.screenOrientation)}
                    </b>
                  </td>
                </div>
                <td>
                  <small>Campanha: </small>
                  <br />
                  <Button
                    type="button"
                    color="transparent"
                    icon="play-btn-fill"
                    onClick={() => openPreview(campaign)}
                  />
                </td>
              </div>
            </tr>
          ))}
        </tbody>
      </Table>
      {!isLoading && campaignsData?.total && (
        <p className="text-end">
          {campaignsData?.total} campanha(s) encontrada(s)
        </p>
      )}
      {campaignsData && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={campaignsData.totalPages}
          currentPage={campaignsData.page}
        />
      )}
      {selectedCampaign && (
        <PlayerCampaignPreview
          isOpen={isPreviewOpen}
          onClose={closePreview}
          campaign={selectedCampaign}
          onEnded={handleCampaignContinuosOnEnded}
        />
      )}
    </div>
  );
}

export default CampaignListPage;
