import React, { useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label
} from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';
import Icon from 'components/Icon';
import classnames from 'classnames';

type Props = InputProps & {
  name: string;
  label?: string;
};

const PasswordField = ({ name, label, ...props }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    field: { ...fieldProps },
    fieldState: { invalid, error }
  } = useController({
    name: name,
    defaultValue: ''
  });

  const type = useMemo(() => {
    return showPassword ? 'text' : 'password';
  }, [showPassword]);

  return (
    <FormGroup className="password-field">
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputGroup>
        <Input
          {...props}
          {...fieldProps}
          invalid={invalid}
          type={type}
          id={name}
        />
        <Button
          className={classnames('password-eye-toggle', { invalid })}
          color="outline-secondary"
          onClick={() => setShowPassword((x) => !x)}
        >
          <Icon
            icon={showPassword ? 'eye-slash-fill' : 'eye-fill'}
            width={20}
            height={20}
          />
        </Button>
        {error && <FormFeedback>{error.message}</FormFeedback>}
      </InputGroup>
    </FormGroup>
  );
};

export default PasswordField;
