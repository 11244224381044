import React, { useMemo, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';

type Props = {
  name: string;
  label?: string;
  options: { value: any; label: string | JSX.Element }[];
  valueDefault?: any;
  classNameRadio?: string;
  disabled?: boolean;
} & InputProps;

const RadioChoicesFiled = ({
  name,
  options,
  label,
  classNameRadio,
  disabled,
  ...props
}: Props) => {
  const {
    field: { value, onChange: onFieldChange },
    fieldState: { error }
  } = useController({
    name,
    defaultValue: props.valueDefault || options[0]?.value
  });

  const handleRadioChange = useCallback(
    (newValue: any) => {
      if (onFieldChange) {
        onFieldChange(newValue);
      }
    },
    [onFieldChange]
  );

  const radioInputs = useMemo(() => {
    return options.map(({ value: optionValue, label: optionLabel }) => (
      <FormGroup check key={optionValue}>
        <div className="pe-2">
          <Input
            type="radio"
            id={`${name}-${optionValue}`}
            {...props}
            invalid={!!error}
            value={optionValue}
            checked={value === optionValue}
            disabled={disabled}
            onChange={() => handleRadioChange(optionValue)}
          />
        </div>
        <div className="pe-2">
          <Label check htmlFor={`${name}-${optionValue}`}>
            {optionLabel}
          </Label>
        </div>
      </FormGroup>
    ));
  }, [name, options, props, value, error, disabled, handleRadioChange]);

  return (
    <FormGroup tag="fieldset">
      {label && <legend className="d-block fs-6">{label}</legend>}
      <div className={classNameRadio}>{radioInputs}</div>
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default RadioChoicesFiled;
