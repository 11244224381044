import Modal from 'components/Modal';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useApiPost, useApiPut } from 'hooks/useApi';
import MediaFile from 'models/MediaFile';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import Form from 'components/Form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const yupSchema = yup.object({
  name: yup.string().required()
});

type FolderModalType = {
  mediaFormData?: MediaFile;
  onSave: () => void;
  onCancel: () => void;
};
const FormMediaModal = ({
  mediaFormData,
  onCancel,
  onSave
}: FolderModalType) => {
  const { mutate: mutateEdit, isPending: isEditing } = useApiPut<any>(
    '/media-manager/medias',
    mediaFormData?.id
  );
  const { mutate: mutateCreateFolder, isPending: isCreating } = useApiPost<any>(
    '/media-manager/folder'
  );

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleSubmit = useCallback(
    (data: { name: string }) => {
      const mutate = mediaFormData?.id ? mutateEdit : mutateCreateFolder;
      mutate(
        {
          ...mediaFormData,
          name: data.name,
          parentId: mediaFormData?.parent?.id
        },
        {
          onSuccess: onSave,
          onError
        }
      );
    },
    [mediaFormData, mutateEdit, mutateCreateFolder, onSave, onError]
  );

  const handleSave = useCallback(() => {
    formMethods.clearErrors();
    formMethods.handleSubmit(handleSubmit)();
  }, [formMethods, handleSubmit]);

  useEffect(() => {
    formMethods.reset({ name: mediaFormData?.name || '' });
  }, [formMethods, mediaFormData]);

  const title = useMemo(() => {
    if (mediaFormData?.isFolder) {
      return mediaFormData?.id ? `Renomear Pasta` : 'Nova Pasta';
    } else {
      return `Renomear Mídia`;
    }
  }, [mediaFormData]);

  const isLoading = isEditing || isCreating;

  if (!mediaFormData) return null;
  return (
    <Form {...formMethods} onSubmit={() => {}}>
      <Modal
        isOpen={!!mediaFormData}
        toggle={onCancel}
        title={title}
        primaryButtonLabel="Salvar"
        primaryButtonAction={handleSave}
        secondaryButtonAction={onCancel}
        secondaryButtonLabel="Cancelar"
        isLoading={isLoading}
        primaryButtonType="submit"
      >
        <TextField
          name="name"
          placeholder="Informe o nome da pasta"
          label="Nome da pasta"
        />
      </Modal>
    </Form>
  );
};

export default FormMediaModal;
