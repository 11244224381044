import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';
import { useController } from 'react-hook-form';

type Props = InputProps & {
  name: string;
  label?: string;
  valueDefault?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileField = ({
  name,
  label,
  valueDefault = '',
  onChange,
  ...props
}: Props) => {
  const {
    field: { value, ...field },
    fieldState: { invalid, error }
  } = useController({ name, defaultValue: valueDefault });

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <Input
        id={name}
        type={props.type || 'text'}
        {...props}
        {...field}
        invalid={invalid}
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e);
        }}
      />
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default FileField;
