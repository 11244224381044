enum CampaignLayout {
  FULL_SCREEN = 'FULL_SCREEN',
  HALF_SCREEN = 'HALF_SCREEN',
  VERTICALLY_SPLIT = 'VERTICALLY_SPLIT',
  HORIZONTALLY_SPLIT = 'HORIZONTALLY_SPLIT'
}

export const campaignLayoutDisplay = (layoutType?: CampaignLayout) => {
  switch (layoutType) {
    case CampaignLayout.FULL_SCREEN:
      return 'Tela Cheia';
    case CampaignLayout.HALF_SCREEN:
      return 'Meia Tela';
    case CampaignLayout.VERTICALLY_SPLIT:
      return 'Divisão Vertical';
    case CampaignLayout.HORIZONTALLY_SPLIT:
      return 'Divisão Horizontal';
    default:
      return '';
  }
};

export default CampaignLayout;
