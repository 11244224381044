import { startsWith } from 'lodash';
import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { MediaFileCampaign } from 'models/MediaFileCampaign';

type ModalCampaignsPreviewProps = {
  mediaCampaingPreview?: MediaFileCampaign;
  isOpen: boolean;
  onClose: () => void;
};

const ModalCampaignsPreview = ({
  isOpen,
  onClose,
  mediaCampaingPreview
}: ModalCampaignsPreviewProps) => {
  if (!mediaCampaingPreview) return null;

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader toggle={onClose}>
        {mediaCampaingPreview.mediaFile?.name}
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center flex-column py-5 modal-body-media-preview">
        {startsWith(mediaCampaingPreview.mediaFile?.mineType, 'image') ? (
          <img
            src={mediaCampaingPreview.editedFile}
            alt={mediaCampaingPreview.mediaFile?.name}
            className="mw-100 mh-100 w-auto h-auto d-block m-auto"
          />
        ) : (
          <video
            data-testid="video-preview"
            controls
            className="h-100 w-100"
            aria-label={mediaCampaingPreview.mediaFile?.name}
          >
            <source
              src={mediaCampaingPreview.editedFile}
              type={mediaCampaingPreview.mediaFile?.mineType}
            />
            Your browser does not support the video tag.
          </video>
        )}
        <ModalFooter>
          <p className="w-100 text-nowrap text-truncate mb-0 small">
            <small>
              Enviado {moment(mediaCampaingPreview.createdAt).calendar()} por{' '}
              {mediaCampaingPreview.mediaFile?.createdBy?.firstName}
            </small>
          </p>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
export default ModalCampaignsPreview;
