enum ScreenOrientation {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE'
}

export const screenOrientationDisplay = (
  screenOrientation?: ScreenOrientation
) => {
  switch (screenOrientation) {
    case ScreenOrientation.PORTRAIT:
      return 'Retrato';
    case ScreenOrientation.LANDSCAPE:
      return 'Paisagem';
    default:
      return '';
  }
};

export default ScreenOrientation;
