import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import AccessPolicy from 'models/AccessPolicy';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useUser from 'hooks/useUser';

const AccessPoliciesListPage = () => {
  const navigate = useNavigate();
  const user = useUser();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: accessPoliciesPage, isLoading } = useApiGet<
    Pagination<AccessPolicy>
  >('/authorization/access-policies', {
    params: filteredData
  });
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
      TypeAction.CREATE
    );
  }, [user]);
  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEntry = useCallback(
    (id: string) => {
      navigate(`/access-policies/${id}`);
    },
    [navigate]
  );
  return (
    <div className="bg-white p-2">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Políticas de acesso</span>
          </p>
        </Col>
        {userCanAdd && (
          <Col className="text-end">
            <Link to={'/access-policies/create'} className="btn btn-primary">
              <Icon icon="plus" /> Criar política
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar política"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {accessPoliciesPage?.items.map((accessPolicy) => (
            <tr
              key={accessPolicy.id}
              role="button"
              onClick={() => goToEntry(accessPolicy.id)}
            >
              <td>
                <small>Nome da politica: </small>
                <br />
                <b>{accessPolicy.name}</b>
              </td>
              <td>
                <small>Recursos: </small>
                <br />
                <b>
                  {accessPolicy.resources.length
                    ? accessPolicy.resources
                        .map((item) => item.label)
                        .sort()
                        .join(', ')
                    : 'Nenhum Recurso associado'}
                </b>
              </td>
            </tr>
          ))}
          {accessPoliciesPage?.items.length === 0 && !isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                Nenhum política de acesso encontrada
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {Boolean(accessPoliciesPage?.total) && (
        <p className="text-end">
          {accessPoliciesPage?.total} política(s) de acesso encontrada(s)
        </p>
      )}
      {accessPoliciesPage && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={accessPoliciesPage.totalPages}
          currentPage={accessPoliciesPage.page}
        />
      )}
    </div>
  );
};

export default AccessPoliciesListPage;
