import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Toast, ToastBody } from 'reactstrap';
import FileUpload from 'types/FileUpload';
import MediaUploader from 'components/MediaUploader';
import Button from 'components/Button';
import classnames from 'classnames';

type Options = {
  extraData?: any;
  callback?: (file: File) => void;
};

type MediaUploadContextType = {
  uploadFiles: (files: File[], options?: Options) => void;
};

export const MediaUploadContext = createContext<MediaUploadContextType>({
  uploadFiles: () => {}
});

const MediaUploadProvider = ({ children }: { children: ReactNode }) => {
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [options, setOptions] = useState<Options>();
  const [minimized, setMinimized] = useState(false);
  const handleOnUploaded = useCallback(
    (file: FileUpload) => {
      options?.callback?.(file.file);
    },
    [options]
  );
  const handleNewUploads: MediaUploadContextType['uploadFiles'] = useCallback(
    (files, options) => {
      const newFiles = files.map(
        (file) =>
          ({
            file,
            progress: 0,
            status: 'waiting',
            id:
              Math.random().toString(36).substring(2, 9) +
              Date.now().toString(),
            extraData: options?.extraData
          } as FileUpload)
      );
      setFiles((files) => [...files, ...newFiles]);
      setOptions(options);
    },
    []
  );

  const updateFile = useCallback((file: FileUpload) => {
    setFiles((files) => {
      const clone = [...files];
      const idx = files.findIndex((f) => f.id === file.id);
      clone[idx] = file;
      return clone;
    });
  }, []);

  const handleRemoveFile = useCallback((file: FileUpload) => {
    setFiles((files) => files.filter((f) => f.id !== file.id));
  }, []);
  const progress = useMemo(
    () =>
      Math.round(
        files.reduce((acc, file) => {
          switch (file.status) {
            case 'uploaded':
              return acc + 100;
            case 'uploading':
              return acc + file.progress;
            default:
              return acc;
          }
        }, 0) /
          files.filter(
            (x) => x.status === 'uploaded' || x.status === 'uploading'
          ).length
      ),
    [files]
  );
  return (
    <MediaUploadContext.Provider
      value={{
        uploadFiles: handleNewUploads
      }}
    >
      {files.length > 0 && (
        <Toast className="position-fixed end-0 bottom-0 z-1 me-2 mb-2">
          <div className="d-flex justify-content-between align-items-center w-100 toast-header">
            <strong>
              Envio de arquivos{!isNaN(progress) && <> - {progress} %</>}
            </strong>
            <div>
              <Button
                color="link"
                className="text-decoration-none text-dark p-1"
                onClick={() => setMinimized((x) => !x)}
              >
                <b>_</b>
              </Button>
              <Button
                disabled={files.some(
                  (x) => x.status === 'uploading' || x.status === 'waiting'
                )}
                icon="x"
                color="link"
                className="text-decoration-none text-dark p-1"
                onClick={() => setFiles([])}
              />
            </div>
          </div>
          <ToastBody
            className={classnames('media-uploader-toast', {
              'd-none': minimized
            })}
          >
            {files.map((file) => (
              <MediaUploader
                key={file.id}
                fileUpload={file}
                onUploadStatusChange={updateFile}
                onClose={() => handleRemoveFile(file)}
                onUploaded={handleOnUploaded}
              />
            ))}
          </ToastBody>
        </Toast>
      )}
      {children}
    </MediaUploadContext.Provider>
  );
};

export default MediaUploadProvider;
