import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import CustomerCompanies from 'models/CustomerCompanies';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';

function CustomerCompaniesListPage() {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: companiesData, isLoading } = useApiGet<
    Pagination<CustomerCompanies>
  >('/customer/companies', {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/customer/companies/${id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.COMPANIES]?.includes(
      TypeAction.CREATE
    );
  }, [user]);
  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Empresas</span>
          </p>
        </Col>
        {userCanAdd && (
          <Col className="text-end">
            <Link to={'/customer/companies/create'} className="btn btn-primary">
              <Icon icon="plus" />
              Adicionar Novo
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar empresa"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {companiesData?.items.map((customerCompanies) => (
            <tr
              key={customerCompanies.id}
              role="button"
              onClick={() => goToEdit(customerCompanies.id)}
            >
              <td>
                <small>Nome Fantasia: </small>
                <br />
                <b>{customerCompanies.name}</b>
              </td>
              <td>
                <small>Razão Social: </small>
                <br />
                <b>{customerCompanies.razaoSocial}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Boolean(companiesData?.total) && (
        <p className="text-end">
          {companiesData?.total} empresa(s) encontrada(s)
        </p>
      )}
      {companiesData && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={companiesData.totalPages}
          currentPage={companiesData.page}
        />
      )}
    </div>
  );
}

export default CustomerCompaniesListPage;
