import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fnApiCall } from 'config/networking';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useToast } from 'hooks/useToast';

export const useInvalidateQuery = (keys: string[]) => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    () => queryClient.invalidateQueries({ queryKey: keys }),
    [queryClient, keys]
  );

  return { invalidate };
};

const useApiMutation = <T = any>(
  method: string,
  url: string,
  id?: string | number,
  mutationOptions?: any
) => {
  const { invalidate } = useInvalidateQuery([url, String(id)!]);
  const { addToast } = useToast();
  return useMutation<T, Error, T>({
    mutationFn: (data: any) => {
      const dataId = data instanceof FormData ? data.get('id') : data?.id;
      const pathId = id ? id : dataId;
      const path = pathId ? `${url}/${pathId}` : url;
      return fnApiCall(path, addToast, {
        method: method,
        data,
        ...mutationOptions
      });
    },
    onSuccess: async () => {
      mutationOptions?.onSuccess?.();
      await invalidate();
    },
    ...mutationOptions
  });
};

export const useApiGet = <T = any>(url: string, options: any = {}) => {
  const { id, params } = options;
  const { addToast } = useToast();
  const urlParams = useMemo(() => {
    const searchParams = params
      ? Object.keys(params).reduce((pre, curr) => {
          if (params[curr] !== undefined) {
            pre.append(curr, params[curr]);
          }
          return pre;
        }, new URLSearchParams({}))
      : '';
    return `?${searchParams}`;
  }, [params]);
  const path = (id ? `${url}/${id}` : url) + urlParams;
  return useQuery<T, Error, T>({
    queryKey: [url, params, id],
    queryFn: ({ signal }) => fnApiCall(path, addToast, { signal }),
    refetchOnWindowFocus: false,
    retry: false,
    ...options
  });
};

export const useApiPost = <T = void>(
  url: string,
  mutationOptions: any = null
) => {
  return useApiMutation<T>('post', url, undefined, mutationOptions);
};

export const useApiPostFile = <T = void>(
  url: string,
  mutationOptions: any = null
) => {
  const [progress, setProgress] = useState(0);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const mutate = useApiMutation<T>('post', url, undefined, {
    ...mutationOptions,
    signal: abortControllerRef.current.signal,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...mutationOptions?.headers
    },
    onUploadProgress: (ev: any) =>
      setProgress(Math.round((ev.loaded * 100) / ev.total))
  });
  return {
    ...mutate,
    progress,
    abort: () => abortControllerRef.current.abort()
  };
};

export const useApiPut = <T = void>(
  url: string,
  id?: string | number,
  mutationOptions?: any
) => {
  return useApiMutation<T>('put', url, id, mutationOptions);
};

export const useApiDelete = <T = void>(
  url: string,
  id?: string,
  mutationOptions: any = null
) => {
  return useApiMutation<T>('delete', url, id, mutationOptions);
};
