import Icon from 'components/Icon';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useToast } from 'hooks/useToast';

const AlertPopups = () => {
  const mapTimeouts = useRef<any>({});
  const { toasts, removeToast } = useToast();
  const keys = useMemo(() => Object.keys(toasts || {}), [toasts]);
  useEffect(() => {
    keys.forEach((key) => {
      if (!mapTimeouts.current[key]) {
        mapTimeouts.current[key] = setTimeout(() => {
          removeToast(key);
        }, 5000);
      }
    });
  }, [toasts, keys, removeToast]);

  const handleRemoveToast = useCallback(
    (key: string) => {
      clearTimeout(mapTimeouts.current[key]);
      removeToast(key);
    },
    [removeToast]
  );

  const renderToast = useCallback(
    (key: string) => {
      const toast = toasts[key];
      if (!toast) return null;
      return (
        <div
          key={key}
          className={`bg-${toast.color} px-2 py-3 text-white rounded-2 d-flex align-items-center justify-content-between fw-bold shadow z-3`}
          role="button"
          onClick={() => handleRemoveToast(key)}
        >
          <div className="w-auto">
            <Icon
              icon={`${toast.icon}`}
              className="me-2"
              width={30}
              height={30}
            />
            {toast.message}
          </div>
          <div className="text-end">
            <Icon icon="x" width={30} height={30} />
          </div>
        </div>
      );
    },
    [handleRemoveToast, toasts]
  );
  if (keys.length === 0) return null;
  return (
    <div className="position-fixed bottom-0 w-75 pb-5 pe-5 end-0 z-3">
      {keys.map((key) => renderToast(key))}
    </div>
  );
};

export default AlertPopups;
