import { Button, Col, Row } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import logo from 'assets/images/logo.webp';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPost } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import PasswordField from 'components/PasswordField';
import { User } from 'models/User';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import { useNavigate } from 'react-router-dom';

declare const document: {
  closePlayer: () => void;
};

const LoginDevicePage = () => {
  const navigate = useNavigate();
  const schema = useMemo(() => {
    return yup
      .object({
        username: yup.string().required('O email é obrigatório.'),
        password: yup.string().required('A senha é obrigatória.')
      })
      .required();
  }, []);
  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost<User>('/auth/login');

  const onSubmitHandler = useCallback(
    (data: any) => {
      loginMutation(data, {
        onError,
        onSuccess: (data) => {
          if (
            data?.resources?.[TypeResource.DEVICES]?.includes(
              TypeAction.CREATE
            ) ||
            data?.resources?.[TypeResource.DEVICES]?.includes(TypeAction.UPDATE)
          ) {
            navigate('/device/attach');
          } else {
            alert(
              'Seu usuário não possui permissão para realizar essa operação, entre em contato com o administrador da sua conta.'
            );
          }
        }
      });
    },
    [loginMutation, onError, navigate]
  );

  return (
    <Row className="d-flex justify-content-center align-items-center vh-100">
      <Col md={4} className="d-flex flex-column mx-auto">
        <img src={logo} alt="logo" className="rounded mx-auto d-block" />
        <p className="py-2 text-center">
          Digite seu endereço de e-mail para entrar
        </p>
        <Form onSubmit={onSubmitHandler} {...methods}>
          <TextField type="text" name="username" placeholder="Email" />
          <PasswordField
            type="password"
            name="password"
            placeholder="Password"
          />
          <Button
            color={methods.formState.isValid ? 'primary' : 'secondary'}
            size="lg"
            className="w-100"
            disabled={isPending}
          >
            Entrar
          </Button>
          <Button
            color="link"
            size="lg"
            className="w-100"
            onClick={() => document.closePlayer()}
            type="button"
          >
            Fechar Player
          </Button>
        </Form>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className=" pt-4">
            Caso tenha esquecido a sua senha, você poderá recupera-la acessando
            via computador.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default LoginDevicePage;
