import React, { ChangeEvent, useCallback } from 'react';
import { Input } from 'reactstrap';
import { useToast } from 'hooks/useToast';
import { useApiPostFile } from 'hooks/useApi';

type ImageUploadProps = {
  onSuccess: (data: any) => void;
  url: string;
};

const ImageUpload = ({ onSuccess, url }: ImageUploadProps) => {
  const { addToast } = useToast();
  const ref = React.useRef<HTMLInputElement>(null);

  const { mutate, isPending } = useApiPostFile(url);

  const handleResetInput = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
      ref.current.type = 'text';
      ref.current.type = 'file';
    }
  }, []);

  const handleOnSuccess = useCallback(
    (data: any) => {
      onSuccess(data);
      handleResetInput();
    },
    [handleResetInput, onSuccess]
  );

  const handleImageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      const formData = new FormData();
      formData.append('picture', file as Blob);
      mutate(formData as any, {
        onSuccess: handleOnSuccess,
        onError: (error: any) => {
          const { errors } = error || ({} as any);
          const message =
            errors?.status === 422 ? errors?.detail?.picture : errors?.detail;
          addToast({
            icon: 'exclamation-triangle',
            color: 'danger',
            message: message || 'Erro no upload da imagem'
          });
          handleResetInput();
        }
      });
    },
    [addToast, handleOnSuccess, handleResetInput, mutate]
  );

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Input
        innerRef={ref}
        name="file"
        type="file"
        onChange={handleImageChange}
        accept="image/*"
        className="w-75"
        aria-label="File Upload"
        disabled={isPending}
      />
    </div>
  );
};

export default ImageUpload;
