import React, { useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col
} from 'reactstrap';
import DeviceFormPage from './DeviceFormPage';
import ResourcesPage from './ResourcesPage';
import PlayerSettingsPage from './PlayerSettingsPage';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';

enum Pages {
  DEVICES = '1',
  RESOURCES = '2',
  PLAYER_SETTINGS = '3'
}

const DevicePage = () => {
  const [activeTab, setActiveTab] = useState<string>(Pages.DEVICES);

  return (
    <div>
      <Col className="bg-white">
        <p className="mt-2 d-flex align-items-center">
          <Link
            to={'/device'}
            className="btn btn-link text-dark text-decoration-none me-2"
          >
            <Icon icon="chevron-left" />
          </Link>
          <span className="h5 fw-bolder mb-0">Lista de Dispositivos</span>
        </p>
      </Col>
      <div>
        <Nav tabs className="mt-2">
          <NavItem className="cursor-pointer">
            <NavLink
              className={classNames({ active: activeTab === Pages.DEVICES })}
              onClick={() => setActiveTab(Pages.DEVICES)}
            >
              Dispositivos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === Pages.RESOURCES })}
              onClick={() => setActiveTab(Pages.RESOURCES)}
            >
              Recursos do Dispositivo
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Pages.PLAYER_SETTINGS
              })}
              onClick={() => setActiveTab(Pages.PLAYER_SETTINGS)}
            >
              Configurações do Player
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="mt-2">
              <DeviceFormPage />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="mt-2">
              <ResourcesPage />
            </div>
          </TabPane>
          <TabPane tabId="3">
            <Row className="mt-3">
              <Col>
                <PlayerSettingsPage />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default DevicePage;
