import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Row,
  Spinner
} from 'reactstrap';
import Icon from 'components/Icon';
import SearchInput from 'components/SearchInput';
import React, { useCallback, useMemo, useState } from 'react';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import { useApiGet } from 'hooks/useApi';
import MediaFile from 'models/MediaFile';
import FormMediaModal from 'components/MediaGrid/FormMediaModal';
import Button from 'components/Button';
// @ts-ignore
import { DropTargetMonitor, useDrop } from 'react-dnd';
// @ts-ignore
import { NativeTypes } from 'react-dnd-html5-backend';
import MediaFilesGrid from 'components/MediaGrid/MediaFilesGrid';
import useUploadMedia from 'hooks/useUploadMedia';
import FileInputButton from 'components/FileInputButton';

type MediaGridProps = {
  title: string;
};

type MediaFileFilter = {
  search?: string;
  parent?: string;
};
const MediaGrid = ({ title }: MediaGridProps) => {
  const user = useUser();
  const [mediaFormItem, setMediaFormItem] = useState<MediaFile>();
  const [filteredData, setFilteredData] = React.useState<MediaFileFilter>({});
  const [parentsPaths, setParentsPaths] = React.useState<MediaFile['parent'][]>(
    []
  );
  const { data: medias, isLoading, refetch } = useApiGet<MediaFile[]>(
    '/media-manager/medias',
    {
      params: {
        parent: filteredData?.parent,
        search: filteredData?.search
      }
    }
  );
  const uploadFiles = useUploadMedia();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.MEDIAS]?.includes(TypeAction.CREATE);
  }, [user]);

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const goToParent = useCallback((newParent: MediaFile['parent']) => {
    setParentsPaths((paths) => [...paths, newParent]);
    setFilteredData((data) => ({ ...data, parent: newParent?.id }));
  }, []);

  const goBackToParent = useCallback(
    (backParent: MediaFile['parent']) => {
      if (backParent) {
        const index = parentsPaths.findIndex(
          (path) => path?.id === backParent?.id
        );
        setParentsPaths(parentsPaths.slice(0, index));
      } else {
        setParentsPaths([]);
      }
      setFilteredData((data) => ({ ...data, parent: backParent?.id }));
    },
    [parentsPaths]
  );

  const handleFolderChange = useCallback(() => {
    setMediaFormItem(undefined);
    refetch();
  }, [refetch]);

  const handleUploadFiles = useCallback(
    (files?: File[] | null) => {
      if (files) {
        uploadFiles(files, {
          extraData: {
            payload: { parent_id: filteredData?.parent }
          },
          callback: () => refetch()
        });
      }
    },
    [filteredData?.parent, refetch, uploadFiles]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        const files = item.files;
        handleUploadFiles(files);
      },
      canDrop: () => Boolean(userCanAdd),
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop()
        };
      }
    }),
    [handleUploadFiles, userCanAdd]
  );

  return (
    <>
      <div className="h-100 d-flex flex-column flex-fill">
        <Row className="d-flex align-items-center bg-white mb-2">
          <Col className="bg-white">
            <p className="mt-2">
              <span className="h5 fw-bolder">{title}</span>
            </p>
          </Col>
          <Col className="text-end">
            {userCanAdd && (
              <>
                <FileInputButton
                  to={'/access-policies/create'}
                  color="primary"
                  icon="upload"
                  className="me-2"
                  accept="image/*,video/*"
                  onFilesChange={handleUploadFiles}
                >
                  Enviar mídia
                </FileInputButton>
                <Button
                  icon="folder-plus"
                  type="button"
                  color="info"
                  className="text-light"
                  onClick={() =>
                    setMediaFormItem({
                      isFolder: true,
                      parent: { id: filteredData?.parent }
                    })
                  }
                >
                  Nova pasta
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Row className="d-flex align-items-center bg-white">
          <Col className="bg-white  mt-1" md="4">
            <SearchInput
              onSearchTextChange={handleSearch}
              placeholder="Pesquisar mídias"
            />
          </Col>
        </Row>
        <hr />
        <Breadcrumb>
          {!filteredData?.search ? (
            <>
              <BreadcrumbItem
                active={!filteredData?.parent}
                role="button"
                className="d-flex justify-content-center align-items-center"
                onClick={() =>
                  filteredData?.parent && goBackToParent(undefined)
                }
              >
                <Icon icon="house-door" className="me-1" /> Mídias
              </BreadcrumbItem>
              {parentsPaths.map((path) => (
                <BreadcrumbItem
                  key={path?.id}
                  role="button"
                  active={path?.id === filteredData?.parent}
                  onClick={() =>
                    path?.id !== filteredData?.parent && goBackToParent(path)
                  }
                >
                  {path?.name}
                </BreadcrumbItem>
              ))}
            </>
          ) : (
            <BreadcrumbItem
              active
              className="d-flex justify-content-center align-items-center"
            >
              Resultado da pesquisa:
            </BreadcrumbItem>
          )}
        </Breadcrumb>
        <div className="media-list" ref={drop}>
          <Card
            color="light"
            className="h-100 overflow-y-scroll flex-fill d-flex"
          >
            {isLoading && (
              <div className="position-absolute h-100 w-100 top-0 start-0 bg-gray-200 opacity-1 z-3 d-flex justify-content-center align-items-center">
                <Spinner color="primary">Loading...</Spinner>
              </div>
            )}
            {canDrop && isOver && userCanAdd && (
              <div className="position-absolute h-100 w-100 top-0 start-0 bg-danger-subtle opacity-4 fs-5 z-3 d-flex justify-content-center align-items-center text-dark">
                Solte os arquivos aqui
              </div>
            )}
            <MediaFilesGrid
              medias={medias}
              reFetchMedias={refetch}
              onEditMedia={setMediaFormItem}
              onGoToFolder={goToParent}
            />
            {medias?.length === 0 && (
              <CardBody className="d-flex justify-content-center align-items-center">
                Nenhuma mídia encontrada, arraste e solte arquivos para o upload
                de mídias
              </CardBody>
            )}
          </Card>
        </div>
      </div>
      <FormMediaModal
        mediaFormData={mediaFormItem}
        onCancel={() => setMediaFormItem(undefined)}
        onSave={handleFolderChange}
      />
    </>
  );
};

export default MediaGrid;
