import React, { useCallback } from 'react';

type InternalsNewsImageItemProps = {
  editedMedia: string;
  onClick?: (url: string) => void;
};

const InternalsNewsImageItem = ({
  editedMedia,
  onClick
}: InternalsNewsImageItemProps) => {
  const handleMediaClick = useCallback(() => {
    onClick?.(editedMedia);
  }, [editedMedia, onClick]);

  return (
    <div className="h-100">
      <div role="button" onClick={handleMediaClick} className="img-thumbnail">
        <img
          alt="Imagem editada"
          src={editedMedia}
          className="w-25 m-auto d-block"
        />
      </div>
    </div>
  );
};

export default InternalsNewsImageItem;
