import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'components/Button';
import AccessPolicy from 'models/AccessPolicy';
import { useForm } from 'react-hook-form';
import Form from 'components/Form';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import TextField from 'components/TextField';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import ResourcesField from 'pages/AccessPolicies/ResourcesField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from 'components/Icon';
import Modal from 'components/Modal';

const yupSchema = yup
  .object({
    name: yup.string().required(),
    resources: yup.array().min(1)
  })
  .required();
const AccessPoliciesFormPage = () => {
  const params = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const user = useUser();
  const { data, isLoading: isFetching } = useApiGet<AccessPolicy>(
    `/authorization/access-policies`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    AccessPolicy
  >('/authorization/access-policies');
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    AccessPolicy
  >('/authorization/access-policies', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/authorization/access-policies',
    params.id
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.ACCESS_POLICIES]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const accessPolicy = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: AccessPolicy) => {
      if (!userCanPerformAction) return;
      const resources = data.resources.filter(
        (resource) =>
          resource.canRead ||
          resource.canUpdate ||
          resource.canDelete ||
          resource.canCreate
      );
      mutate(
        { ...data, resources },
        {
          onError,
          onSuccess: () => {
            addToast({
              color: 'success',
              icon: 'check',
              message: 'Política de acesso salva com sucesso'
            });
            navigate('/access-policies');
          }
        }
      );
    },
    [addToast, mutate, navigate, onError, userCanPerformAction]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() ||
            'Erro ao excluir política de acesso'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Política de acesso excluída com sucesso'
        });
        navigate('/access-policies');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  useEffect(() => {
    if (accessPolicy) {
      formMethods.reset(accessPolicy as any);
    }
  }, [accessPolicy, formMethods]);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/access-policies'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Políticas de acesso</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <TextField
                name="name"
                label="Nome da política"
                placeholder="Digite o nome da política"
                disabled={!userCanPerformAction}
                defaultValue={accessPolicy?.name}
              />
              <ResourcesField disabled={!userCanPerformAction} />
            </>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir a política de acesso? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};

export default AccessPoliciesFormPage;
