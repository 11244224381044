import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useMemo, useState } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import { Playlist } from 'models/Playlist';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import { screenOrientationDisplay } from 'enum/ScreenOrientation';
import Button from 'components/Button';
import PlayerPlaylistPreview from 'components/Playlist/PlayerPlaylistPreview';

const PlaylistListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: playlistsData, isLoading } = useApiGet<Pagination<Playlist>>(
    '/media-manager/playlists',
    {
      params: filteredData
    }
  );

  const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/media-manager/playlists/${id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.PLAYLISTS]?.includes(
      TypeAction.CREATE
    );
  }, [user]);

  const openPreview = useCallback((playlist: Playlist) => {
    setSelectedPlaylist(playlist);
    setIsPreviewOpen(true);
  }, []);

  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    setSelectedPlaylist(null);
  }, []);
  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Playlists</span>
          </p>
        </Col>
        {userCanAdd && (
          <Col className="text-end">
            <Link
              to={'/media-manager/playlists/create'}
              className="btn btn-primary"
            >
              <Icon icon="plus" />
              Adicionar Novo
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar playlist"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {playlistsData?.items.map((playlist) => (
            <tr>
              <div className="d-flex d-flex justify-content-between align-items-center">
                <div
                  className="d-flex justify-content-between align-items-center w-100"
                  key={playlist.id}
                  role="button"
                  onClick={() => goToEdit(playlist.id ?? '')}
                >
                  <td className="w-100">
                    <small>Playlist: </small>
                    <br />
                    <b>{playlist.name}</b>
                  </td>
                  <td className="w-100">
                    <small>Grupo: </small>
                    <br />
                    <b>{playlist.deviceGroup?.name}</b>
                  </td>
                  <td className="w-100">
                    <small>Quantidade de itens: </small>
                    <br />
                    <b>{playlist.items?.length}</b>
                  </td>
                  <td className="w-100">
                    <small>Duração: </small>
                    <br />
                    <b>
                      {playlist?.items?.reduce(
                        (total, media) => total + (media?.duration || 0),
                        0
                      )}{' '}
                      segundos
                    </b>
                  </td>
                  <td className="w-100">
                    <small>Orientação: </small>
                    <br />
                    <b>
                      {screenOrientationDisplay(playlist.screenOrientation)}
                    </b>
                  </td>
                  <td className="w-100">
                    <small>Ativo:</small>
                    <br />
                    <b>{playlist.active ? 'Sim' : 'Não'}</b>
                  </td>
                </div>
                <td>
                  <small>Playlist: </small>
                  <br />
                  <Button
                    type="button"
                    color="transparent"
                    icon="play-btn-fill"
                    onClick={() => openPreview(playlist)}
                  />
                </td>
              </div>
            </tr>
          ))}
        </tbody>
      </Table>
      {!isLoading && playlistsData?.total && (
        <p className="text-end">
          {playlistsData?.total} playlist(s) encontrada(s)
        </p>
      )}
      {playlistsData && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={playlistsData.totalPages}
          currentPage={playlistsData.page}
        />
      )}
      {selectedPlaylist && (
        <PlayerPlaylistPreview
          playlist={selectedPlaylist}
          isOpen={isPreviewOpen}
          onClose={closePreview}
        />
      )}
    </div>
  );
};

export default PlaylistListPage;
