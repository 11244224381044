import React, { useState } from 'react';
// @ts-ignore
import { useDrag } from 'react-dnd';
import InternalsNews from 'models/InternalsNews';
import { Table } from 'reactstrap';
import Button from 'components/Button';
import moment from 'moment';
import InternalsNewsPreview from './InternalsNewsPreview';

type InternalsNewsItemProps = {
  internalsNews: InternalsNews;
};

const InternalsNewsItem = ({ internalsNews }: InternalsNewsItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'InternalsNews',
    item: { id: internalsNews.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [
    selectedInternalsNews,
    setSelectedInternalsNews
  ] = useState<InternalsNews | null>(null);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (internalsNews: InternalsNews) => {
    setSelectedInternalsNews(internalsNews);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedInternalsNews(null);
  };

  return (
    <tr ref={drag}>
      <Table>
        {!isDragging ? (
          <tr className="campaign-medias-list-item">
            <td>
              <tr className="d-flex justify-content-between align-items-center">
                <td className="w-100 me-4">
                  <small>Título: </small>
                  <br />
                  <b>{internalsNews.title}</b>
                </td>
                <td className="w-100 mx-4">
                  <small>Preview do texto: </small>
                  <br />
                  <b className="text-truncate-wrap">
                    {internalsNews.description}
                  </b>
                </td>
                <td className="w-100 mx-4">
                  <small>Data: </small>
                  <br />
                  <b>{moment(internalsNews.startDate).format('LLL')}</b>
                </td>
                <td className="ms-4">
                  <small>Notícia: </small>
                  <br />
                  <Button
                    type="button"
                    color="transparent"
                    icon="play-btn-fill"
                    onClick={() => openPreview(internalsNews)}
                  />
                </td>
              </tr>
            </td>
          </tr>
        ) : (
          <tr className="campaign-medias-list-item">
            <td className="bg-dark-subtle"></td>
          </tr>
        )}
      </Table>
      <InternalsNewsPreview
        isOpen={isPreviewOpen}
        onClose={closePreview}
        news={selectedInternalsNews!}
      />
    </tr>
  );
};

export default InternalsNewsItem;
