import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import CustomerUser from 'models/CustomerUser';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';

const CustomerUsersListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: customerUsersPage, isLoading } = useApiGet<
    Pagination<CustomerUser>
  >('/customer/users', {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/customer/users/${id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
  }, [user]);
  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Usuários</span>
          </p>
        </Col>
        {userCanAdd && (
          <Col className="text-end">
            <Link to={'/customer/users/create'} className="btn btn-primary">
              <Icon icon="plus" />
              Adicionar Novo
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar usuário"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {customerUsersPage?.items.map((customerUsers) => (
            <tr
              key={customerUsers.id}
              role="button"
              onClick={() => goToEdit(customerUsers.id)}
            >
              <td>
                <small>Nome: </small>
                <br />
                <b>{customerUsers.firstName}</b>
              </td>
              <td>
                <small>Email: </small>
                <br />
                <b>{customerUsers.email}</b>
              </td>
              <td>
                <small>Política: </small>
                <br />
                <b>
                  {customerUsers.principalUser
                    ? 'Super Usuário'
                    : customerUsers.policies
                        .map((policy) => policy.name)
                        .join(', ')}
                </b>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Boolean(customerUsersPage?.total) && (
        <p className="text-end">
          {customerUsersPage?.total} usuário(s) encontrado(s)
        </p>
      )}
      {customerUsersPage && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={customerUsersPage.totalPages}
          currentPage={customerUsersPage.page}
        />
      )}
    </div>
  );
};

export default CustomerUsersListPage;
