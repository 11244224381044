import classnames from 'classnames';
import Icon from 'components/Icon';

export const LabelFullScreen = () => (
  <div className="position-relative bg-transparent border-0">
    <p className="position-absolute top-50 start-50 translate-middle text-secondary">
      B1
    </p>
    <Icon icon="square" width={150} height={150} className="text-secondary" />
  </div>
);

export const LabelHorizontallySplit = () => (
  <div className="position-relative bg-transparent border-0">
    <p className="position-absolute top-50 start-50 translate-middle text-secondary">
      B1
    </p>
    <p className="position-absolute top-50 start-50 mt-5 translate-middle text-secondary">
      B2
    </p>
    <Icon
      icon="layout-sidebar"
      width={150}
      height={150}
      className={classnames('text-secondary', 'icon-reverse')}
    />
  </div>
);

export const LabelVerticallySplit = () => (
  <div className="position-relative bg-transparent border-0">
    <p className="fs-6 position-absolute top-50 start-50 translate-middle text-secondary">
      B1
    </p>
    <p className="fs-6 position-absolute top-50 end-0 me-2 translate-middle text-secondary">
      B2
    </p>
    <Icon
      icon="layout-sidebar-reverse"
      width={150}
      height={150}
      className="text-secondary"
    />
  </div>
);

export const LabelHalfSplit = () => (
  <div className="position-relative bg-transparent border-0">
    <p className="position-absolute top-50 start-0 ms-5 translate-middle text-secondary">
      B1
    </p>
    <p className="position-absolute top-50 end-0 me-4 translate-middle text-secondary">
      B2
    </p>
    <Icon
      icon="layout-split"
      width={150}
      height={150}
      className="text-secondary"
    />
  </div>
);
