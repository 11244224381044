import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import TextField from 'components/TextField';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import RadioChoicesFiled from 'components/RadioChoicesField';
import ScreenOrientation, {
  screenOrientationDisplay
} from 'enum/ScreenOrientation';
import CampaignLayout from 'enum/CampaignLayout';
import { Campaign, MediaFileCampaign } from 'models/MediaFileCampaign';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import {
  LabelFullScreen,
  LabelHalfSplit,
  LabelHorizontallySplit,
  LabelVerticallySplit
} from './CampaignLabels';
import SwitchField from 'components/SwitchField';
import MediaGrid from 'components/MediaGrid';
import { withDragAndDropProvider } from 'contexts/dragAndDropProvider';
import { Loader } from 'react-bootstrap-typeahead';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import MediaEditor, {
  EditedMedia,
  MediaEditorRef
} from 'components/MediaEditor';
import MediaCampaignPosition from 'enum/MediaCampaignPosition';
import CampaignPositionList from 'components/Campaign/CampaignPositionList';
import MediaFile from 'models/MediaFile';
import ModalCampaignsPreview from 'components/ModalCampaignsPreview';
import PlayerCampaignPreview from 'components/Campaign/PlayerCampaignPreview';

const schema = yup
  .object({
    name: yup.string().required('O nome é obrigatório.'),
    screenOrientation: yup
      .mixed<ScreenOrientation>()
      .oneOf(Object.values(ScreenOrientation))
      .required(),
    layoutType: yup
      .mixed<CampaignLayout>()
      .oneOf(Object.values(CampaignLayout))
      .required(),
    active: yup.boolean().required('A campanha deve estar ativa ou inativa.')
  })
  .required();

const CampaignEditPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const refMediaEditor = useRef<MediaEditorRef>(null);
  const { data, isLoading: isFetching, refetch } = useApiGet<Campaign>(
    `/media-manager/campaigns`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateEditCampaign, isPending: isUpdating } = useApiPut<
    Campaign
  >('/media-manager/campaigns', params.id);

  const { mutate: mutateMediaCampaign, isPending: isUpdatingMedia } = useApiPut<
    Campaign
  >(`/media-manager/campaigns/${params.id}/medias/orders`, '', {
    onSuccess: refetch
  });

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/media-manager/campaigns',
    params.id
  );

  const { mutate: mutateCreateMedia, isPending: isCreatingMedia } = useApiPost<
    MediaFileCampaign & { mediaFile: any }
  >(`/media-manager/campaigns/${params.id}/medias`);

  const userCanEdit = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.UPDATE
    );
  }, [user]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const formMethods = useForm({
    resolver: yupResolver(schema)
  });
  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const campaign = params.id ? data : undefined;

  const onSubmitHandler = useCallback(
    (data: Campaign) => {
      mutateEditCampaign(data, {
        onError,
        onSuccess: () => {
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Campanha atualizada com sucesso.'
          });
          navigate('/media-manager/campaigns');
        }
      });
    },
    [addToast, mutateEditCampaign, navigate, onError]
  );

  const handleOnDelete = useCallback(() => {
    if (!userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir a campanha'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Campanha excluída com sucesso'
        });
        navigate('/media-manager/campaigns');
      }
    });
  }, [addToast, mutateDelete, navigate, userCanDelete]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: screenOrientationDisplay(ScreenOrientation.PORTRAIT)
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: screenOrientationDisplay(ScreenOrientation.LANDSCAPE)
    }
  ];
  const typeOptionsScreen = [
    { value: CampaignLayout.FULL_SCREEN, label: <LabelFullScreen /> },
    {
      value: CampaignLayout.HORIZONTALLY_SPLIT,
      label: <LabelHorizontallySplit />
    },
    { value: CampaignLayout.VERTICALLY_SPLIT, label: <LabelVerticallySplit /> },
    { value: CampaignLayout.HALF_SCREEN, label: <LabelHalfSplit /> }
  ];

  useEffect(() => {
    if (campaign) {
      formMethods.reset({
        name: campaign.name,
        active: campaign.active,
        layoutType: campaign.layoutType,
        screenOrientation: campaign.screenOrientation
      } as any);
    }
  }, [campaign, formMethods]);

  const mediasCampaignB1 = useMemo(() => {
    return campaign?.medias?.filter(
      (media) => media.position === MediaCampaignPosition.B1
    );
  }, [campaign?.medias]);

  const mediasCampaignB2 = useMemo(() => {
    return campaign?.medias?.filter(
      (media) => media.position === MediaCampaignPosition.B2
    );
  }, [campaign?.medias]);

  const handleOnFinish = useCallback(
    (
      mediaFile: MediaFile,
      mediaEdited: EditedMedia,
      position?: MediaCampaignPosition
    ) => {
      const { url, cloudinaryJson } = mediaEdited;
      const newOrder =
        position === MediaCampaignPosition.B1
          ? mediasCampaignB1?.length
          : mediasCampaignB2?.length;
      mutateCreateMedia(
        {
          editedFile: url,
          order: newOrder || 0,
          duration: 5,
          cloudinaryJson,
          active: true,
          position: position,
          mediaFile: mediaFile.id
        },
        {
          onSuccess: () => {
            refetch();
          }
        }
      );
    },
    [mutateCreateMedia, refetch, mediasCampaignB1, mediasCampaignB2]
  );

  const handleOnEditMedia = useCallback(
    (mediaFile: MediaFile, position: MediaCampaignPosition) => {
      refMediaEditor?.current?.showEditor(mediaFile, {
        orientation: campaign?.screenOrientation!,
        position: position,
        layout: campaign?.layoutType!
      });
    },
    [campaign?.layoutType, campaign?.screenOrientation]
  );

  const handleReOrderMedias = useCallback(
    (medias: MediaFileCampaign[]) => {
      mutateMediaCampaign(
        medias.map((media) => ({
          mediaId: media.id,
          order: media.order
        })) as any
      );
    },
    [mutateMediaCampaign]
  );

  const [mediaToPreview, setMediaToPreview] = useState<MediaFileCampaign>();

  const handleMediaClick = useCallback((media: MediaFileCampaign) => {
    setMediaToPreview(media);
  }, []);

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const handleCampaignContinuosOnEnded = () => {
    setIsPreviewOpen(true);
  };

  if (!params.id) return null;

  return (
    <div className="bg-white p-2 h-100">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center bg-white mb-2">
          <Col className="bg-white">
            <p className="mt-2 d-flex align-items-center">
              <Link
                to={'/media-manager/campaigns'}
                className="btn btn-link text-dark text-decoration-none me-2"
              >
                <Icon icon="chevron-left" />
              </Link>
              <span className="h5 fw-bolder mb-0">Campanha</span>
            </p>
          </Col>
          <Col className="text-end">
            {userCanDelete && (
              <Button
                type="button"
                color="danger"
                loading={isDeleting}
                icon="trash"
                className="me-2"
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Excluir
              </Button>
            )}
            {userCanEdit && (
              <Button
                type="submit"
                color="primary"
                icon="floppy"
                loading={isUpdating}
              >
                Salvar
              </Button>
            )}
          </Col>
        </Row>
        {isFetching ? (
          <Loader />
        ) : (
          <Row>
            <p className="h5 fw-bolder">Configurações da campanha</p>
            <hr className="mb-4" />
            <TextField
              label="Nome"
              name="name"
              className="w-50"
              defaultValue={campaign?.name}
              disabled={!userCanEdit}
            />
            <RadioChoicesFiled
              label="Selecione o tipo de tela:"
              name="layoutType"
              options={typeOptionsScreen}
              classNameRadio="d-flex"
              disabled
            />
            <RadioChoicesFiled
              label="Selecione a orientação da imagem:"
              name="screenOrientation"
              options={typeOptionsOrientation}
              classNameRadio="d-flex"
              disabled
            />
            <SwitchField name="active" label="Ativa" disabled={!userCanEdit} />
            <hr />
            <Row>
              <Col md={6}>
                <div>
                  <MediaGrid title="Gerenciamento de Mídias" />
                </div>
              </Col>
              <Col md={6}>
                <p className="my-2 d-flex align-items-center justify-content-between">
                  <span className="h5 fw-bolder mt-1">
                    Montagem da campanha
                  </span>
                  <Button
                    className="white ms-2 border-0"
                    color="danger"
                    type="button"
                    onClick={() => openPreview(campaign!)}
                  >
                    <Icon icon="play-btn-fill" className="me-3" />
                    Reproduzir Campanha
                  </Button>
                </p>
                <br />
                <br />
                <hr />
                <p className="mb-3 text-body-secondary">
                  <Icon icon="house-door" className="me-1" /> Campanha
                </p>
                <div className="mt-2 mb-2">
                  <b>Solte aqui os arquivos serão exibidos na posição B1</b>
                </div>
                <CampaignPositionList
                  position={MediaCampaignPosition.B1}
                  idCampaign={params.id}
                  mediasCampaign={mediasCampaignB1}
                  onAddMedia={handleOnEditMedia}
                  onReOrderMedias={handleReOrderMedias}
                  isLoading={isUpdatingMedia || isUpdating || isCreatingMedia}
                  onClick={handleMediaClick}
                />
                {campaign?.layoutType !== CampaignLayout.FULL_SCREEN && (
                  <>
                    <div className="mb-2">
                      <b>Solte aqui os arquivos serão exibidos na posição B2</b>
                    </div>
                    <CampaignPositionList
                      position={MediaCampaignPosition.B2}
                      idCampaign={params.id}
                      mediasCampaign={mediasCampaignB2}
                      onAddMedia={handleOnEditMedia}
                      onReOrderMedias={handleReOrderMedias}
                      isLoading={
                        isUpdatingMedia || isUpdating || isCreatingMedia
                      }
                      onClick={handleMediaClick}
                    />
                  </>
                )}
                <MediaEditor onFinish={handleOnFinish} ref={refMediaEditor} />
              </Col>
            </Row>
          </Row>
        )}
      </Form>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir a campanha? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <ModalCampaignsPreview
        mediaCampaingPreview={mediaToPreview!}
        isOpen={!!mediaToPreview}
        onClose={() => setMediaToPreview(undefined)}
      />
      {selectedCampaign && (
        <PlayerCampaignPreview
          isOpen={isPreviewOpen}
          onClose={closePreview}
          campaign={selectedCampaign}
          onEnded={handleCampaignContinuosOnEnded}
        />
      )}
    </div>
  );
};

export default withDragAndDropProvider(CampaignEditPage);
