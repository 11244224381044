import MediaFile from 'models/MediaFile';
import React, { useCallback } from 'react';
// @ts-ignore
import { useDrop } from 'react-dnd';
import MediaItemCard from 'components/MediaGrid/MediaItemCard';
import { useApiPut } from 'hooks/useApi';
import { Spinner } from 'reactstrap';

type MediaItemProps = {
  mediaFile: MediaFile;
  onClick?: (media: MediaFile) => void;
  onEdit?: (media: MediaFile) => void;
  reFetchMedias?: () => void;
};
const MediaItem = ({
  mediaFile,
  onClick,
  reFetchMedias,
  onEdit
}: MediaItemProps) => {
  const { mutate, isPending } = useApiPut<any>('/media-manager/medias');
  const handleDrop = useCallback(
    (mediaDropped: MediaFile) => {
      mutate(
        { ...mediaDropped, parentId: mediaFile?.id },
        {
          onSuccess: reFetchMedias
        }
      );
    },
    [mediaFile?.id, mutate, reFetchMedias]
  );
  const [, drop] = useDrop<MediaFile>(
    () => ({
      accept: 'MediaItem',
      drop: handleDrop,
      canDrop: (mediaDropped) =>
        Boolean(mediaDropped.id !== mediaFile?.id && mediaFile?.isFolder)
    }),
    [mediaFile]
  );
  return (
    <>
      <div className="m-2 media-item" ref={drop}>
        <MediaItemCard
          mediaFile={mediaFile}
          reFetchMedias={reFetchMedias}
          onEdit={onEdit}
          onClick={onClick}
        />
      </div>
      {isPending && (
        <div className="position-absolute h-100 w-100 top-0 start-0 bg-gray-200 opacity-1 z-3 d-flex justify-content-center align-items-center">
          <Spinner color="primary">Loading...</Spinner>
        </div>
      )}
    </>
  );
};
export default MediaItem;
