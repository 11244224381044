import React from 'react';
import Modal from './Modal';
import Notifications from 'models/Notification';

type ModalNotificationProps = {
  isOpen: boolean;
  toggle: () => void;
  notification: Notifications;
};

const ModalNotification = ({
  isOpen,
  toggle,
  notification
}: ModalNotificationProps) => {
  const { title, message, image } = notification;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={title}
      primaryButtonLabel="Fechar"
      primaryButtonAction={toggle}
    >
      <div className="modal-body">
        <p className="text-break">{message}</p>
        {image && <img src={image} className="w-100 mt-3" alt="Notification" />}
      </div>
    </Modal>
  );
};

export default ModalNotification;
