import React from 'react';
import { useController } from 'react-hook-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';

type Props = {
  name: string;
  label?: JSX.Element | string;
} & InputProps;

const CheckboxField = ({ name, label, ...props }: Props) => {
  const {
    field: fieldProps,
    fieldState: { error }
  } = useController({
    name,
    defaultValue: props.defaultValue || false
  });

  return (
    <FormGroup check>
      <Input type="checkbox" id={name} {...fieldProps} invalid={!!error} />{' '}
      <Label check htmlFor={name}>
        {label}
      </Label>
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default CheckboxField;
