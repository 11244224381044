import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import Device from 'models/Device';
import useUser from 'hooks/useUser';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';

const DeviceListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: device, isLoading } = useApiGet<Pagination<Device>>('/device', {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/device/${id}`);
    },
    [navigate]
  );
  const user = useUser();
  const userCanAdd = useMemo(() => {
    return user?.resources?.[TypeResource.DEVICES]?.includes(TypeAction.CREATE);
  }, [user]);
  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Dispositivos</span>
          </p>
        </Col>
        {userCanAdd && (
          <Col className="text-end">
            <Link to={'/device/create'} className="btn btn-primary">
              <Icon icon="plus" />
              Adicionar Novo
            </Link>
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar dispositivos"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData((data) => ({ ...data, pageSize }))
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {device?.items.map((deviceList) => (
            <tr
              key={deviceList.id}
              role="button"
              onClick={() => goToEdit(deviceList.id!)}
            >
              <td>
                <small>Nome: </small>
                <br />
                <b>{deviceList.name}</b>
              </td>
              <td>
                <small>Grupo: </small>
                <br />
                <b>{deviceList.group?.name}</b>
              </td>
              <td>
                <small>Local: </small>
                <br />
                <b>{deviceList.local}</b>
              </td>
              <td>
                <small>S.O: </small>
                <br />
                <b>{deviceList.player?.so || '-'}</b>
              </td>
              <td>
                <small>Marca: </small>
                <br />
                <b>{deviceList.player?.brand || '-'}</b>
              </td>
              <td>
                <small>Modelo: </small>
                <br />
                <b>{deviceList.player?.model || '-'}</b>
              </td>
              <td>
                <small>Ativo:</small>
                <br />
                <b>{deviceList.active ? 'Sim' : 'Não'}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Boolean(device?.total) && (
        <p className="text-end">{device?.total} dispositivo(s) encontrado(s)</p>
      )}
      {device && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={device.totalPages}
          currentPage={device.page}
        />
      )}
    </div>
  );
};

export default DeviceListPage;
