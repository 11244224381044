import { startsWith } from 'lodash';
import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { MediaFileAndCampaignPlaylist } from 'models/Playlist';

type ModalPlaylistsPreviewProps = {
  mediaPlaylistPreview?: MediaFileAndCampaignPlaylist;
  isOpen: boolean;
  onClose: () => void;
};

const ModalPlaylistsPreview = ({
  isOpen,
  onClose,
  mediaPlaylistPreview
}: ModalPlaylistsPreviewProps) => {
  if (!mediaPlaylistPreview) return null;

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader toggle={onClose}>
        {mediaPlaylistPreview.mediaFile?.name}
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center flex-column py-5 modal-body-media-preview">
        {startsWith(mediaPlaylistPreview.mediaFile?.mineType, 'image') ? (
          <img
            src={mediaPlaylistPreview.editedFile}
            alt={mediaPlaylistPreview.mediaFile?.name}
            className="mw-100 mh-100 w-auto h-auto d-block m-auto"
          />
        ) : (
          <video
            data-testid="video-preview"
            controls
            className="h-100 w-100"
            aria-label={mediaPlaylistPreview.mediaFile?.name}
          >
            <source
              src={mediaPlaylistPreview.editedFile}
              type={mediaPlaylistPreview.mediaFile?.mineType}
            />
            Your browser does not support the video tag.
          </video>
        )}
        <ModalFooter>
          <p className="w-100 text-nowrap text-truncate mb-0 small">
            <small>
              Enviado por {mediaPlaylistPreview.mediaFile?.createdBy?.firstName}
            </small>
          </p>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
export default ModalPlaylistsPreview;
