import React, { useState, useCallback } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Icon from './Icon';
import ModalNotification from './ModalNotification';
import Notifications from 'models/Notification';
import { useApiGet, useApiPut } from 'hooks/useApi';
import classNames from 'classnames';

const NotificationButton = () => {
  const { data: notificationsData } = useApiGet<Notifications[]>(
    '/customer/notifications'
  );
  const { mutate } = useApiPut<Notifications>(`/customer/notifications`);
  const { data: unreadCountData } = useApiGet<number>(
    '/customer/notifications/unread-count'
  );

  const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(
    false
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [
    selectedNotification,
    setSelectedNotification
  ] = useState<Notifications | null>(null);
  const [unreadCountNotification, setUnreadCountNotification] = useState(
    unreadCountData ?? 0
  );

  const toggleNotificationMenu = () =>
    setNotificationDropdownOpen((prevState) => !prevState);
  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const handleNotificationClick = useCallback(
    (notification: Notifications) => {
      setSelectedNotification(notification);
      toggleModal();
      if (notification.id) {
        mutate({
          id: notification.id + '/read'
        } as any);
        if (unreadCountNotification > 0) {
          setUnreadCountNotification((prevUnreadCount) => prevUnreadCount - 1);
        }
      }
    },
    [mutate, unreadCountNotification]
  );

  return (
    <>
      <Dropdown
        isOpen={notificationDropdownOpen}
        toggle={toggleNotificationMenu}
      >
        <DropdownToggle color="none" className="d-flex align-items-center">
          <Icon icon="bell" />
          {unreadCountNotification > 0 && (
            <span className="position-absolute top-0 start-75 translate-middle badge rounded-pill bg-danger">
              {unreadCountNotification}
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu
          className={classNames('dropdown-open')}
          data-bs-popper="static"
        >
          {notificationsData?.map((notification) => (
            <DropdownItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
            >
              <p className={!notification.read ? 'fw-bold' : 'fst-normal'}>
                {notification.title}
              </p>
              <small className="d-block text-truncate">
                {notification.message}
              </small>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {selectedNotification && (
        <ModalNotification
          isOpen={modalOpen}
          toggle={toggleModal}
          notification={selectedNotification}
        />
      )}
    </>
  );
};

export default NotificationButton;
