import React from 'react';
import { useController } from 'react-hook-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';

type SwitchProps = {
  name: string;
  label?: JSX.Element | string;
  disabled?: boolean;
  defaultValue?: boolean;
} & InputProps;

const SwitchField = ({
  name,
  label,
  disabled,
  defaultValue,
  ...props
}: SwitchProps) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    defaultValue: props.defaultValue
  });

  return (
    <FormGroup switch>
      <Input
        type="switch"
        id={name}
        onChange={onChange}
        invalid={!!error}
        disabled={disabled}
        checked={value}
      />
      <Label check htmlFor={name}>
        {label}
      </Label>
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default SwitchField;
