import { Campaign } from 'models/MediaFileCampaign';
import Button from 'components/Button';
import ScreenOrientation from 'enum/ScreenOrientation';
import CampaignLayout from 'enum/CampaignLayout';
import MediaCampaignPreview from './MediaCampaignPreview';
import MediaCampaignPosition from 'enum/MediaCampaignPosition';
import classNames from 'classnames';
import React, { useEffect } from 'react';

type PlayerCampaignPreviewProps = {
  campaign: Campaign;
  isOpen: boolean;
  onClose: () => void;
  onEnded: () => void;
};

const PlayerCampaignPreview = ({
  campaign,
  isOpen,
  onClose,
  onEnded
}: PlayerCampaignPreviewProps) => {
  const second = 1000;

  const mediaB1 =
    campaign?.medias?.filter(
      (media) => media.position === MediaCampaignPosition.B1
    ) || [];
  const mediaB2 =
    campaign?.medias?.filter(
      (media) => media.position === MediaCampaignPosition.B2
    ) || [];

  const aspectRatioClass =
    campaign?.screenOrientation === ScreenOrientation.PORTRAIT
      ? 'aspect-ratio-box-3-4'
      : 'aspect-ratio-box-4-3';

  useEffect(() => {
    if (campaign && campaign.medias?.length) {
      const duration =
        campaign.medias.reduce((acc, media) => acc + media.duration!, 0) *
        second;
      const timeout = setTimeout(onEnded, duration);

      return () => clearTimeout(timeout);
    }
  }, [campaign, onEnded]);

  if (!isOpen || !campaign?.medias?.length) return null;

  return (
    <div className="fullscreen-container">
      <Button
        className="close-button bg-transparent border-0"
        type="button"
        icon="x-circle-fill"
        onClick={onClose}
      />
      <div className={classNames('half-screen-container', aspectRatioClass)}>
        {campaign.layoutType === CampaignLayout.FULL_SCREEN && (
          <MediaCampaignPreview medias={mediaB1} />
        )}
        {campaign.layoutType === CampaignLayout.HALF_SCREEN && (
          <>
            <div className="half-screen">
              <MediaCampaignPreview medias={mediaB1} />
            </div>
            <div className="half-screen">
              <MediaCampaignPreview medias={mediaB2} />
            </div>
          </>
        )}
        {campaign.layoutType === CampaignLayout.VERTICALLY_SPLIT && (
          <>
            <div className="vertically-screen-b1">
              <MediaCampaignPreview medias={mediaB1} />
            </div>
            <div className="vertically-screen-b2">
              <MediaCampaignPreview medias={mediaB2} />
            </div>
          </>
        )}
      </div>
      <div
        className={classNames(
          'horizontally-screen-container',
          aspectRatioClass
        )}
      >
        {campaign.layoutType === CampaignLayout.HORIZONTALLY_SPLIT && (
          <>
            <div className="horizontally-screen-b1">
              <MediaCampaignPreview medias={mediaB1} />
            </div>
            <div className="horizontally-screen-b2">
              <MediaCampaignPreview medias={mediaB2} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PlayerCampaignPreview;
