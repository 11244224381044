import { FormProvider, UseFormReturn } from 'react-hook-form';
import { PropsWithChildren, useCallback } from 'react';
import Icon from 'components/Icon';
import { Alert } from 'reactstrap';

type Props = PropsWithChildren<
  UseFormReturn<any> & {
    onSubmit: (data: any) => void;
  }
>;
const Form = ({ onSubmit, children, ...props }: Props) => {
  const onSubmitWrapper = useCallback((data: any) => onSubmit(data), [
    onSubmit
  ]);
  return (
    <FormProvider {...props}>
      <form
        onSubmit={(e) => {
          props.clearErrors();
          props.handleSubmit(onSubmitWrapper)(e);
        }}
      >
        {props.formState.errors?.root?.genericError && (
          <Alert color="danger" className="d-flex align-content-center">
            <Icon icon="exclamation-triangle" width={20} height={20} />
            <span className="ms-2">
              {props.formState.errors.root.genericError.message}
            </span>
          </Alert>
        )}
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
