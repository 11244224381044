import React, { useCallback } from 'react';
import TextField from 'components/TextField';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { useApiPost } from 'hooks/useApi';
import RadioChoicesFiled from 'components/RadioChoicesField';
import ScreenOrientation, {
  screenOrientationDisplay
} from 'enum/ScreenOrientation';
import CampaignLayout from 'enum/CampaignLayout';
import { MediaFileCampaign } from 'models/MediaFileCampaign';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import {
  LabelFullScreen,
  LabelHalfSplit,
  LabelHorizontallySplit,
  LabelVerticallySplit
} from './CampaignLabels';

const schema = yup
  .object({
    name: yup.string().required('O nome é obrigatório.'),
    screenOrientation: yup
      .mixed<ScreenOrientation>()
      .oneOf(Object.values(ScreenOrientation))
      .required(),
    layoutType: yup
      .mixed<CampaignLayout>()
      .oneOf(Object.values(CampaignLayout))
      .required()
  })
  .required();

const CampaignCreatePage = () => {
  const navigate = useNavigate();
  const { mutate: mutateCreate } = useApiPost<MediaFileCampaign>(
    '/media-manager/campaigns'
  );
  const formMethods = useForm({
    resolver: yupResolver(schema)
  });
  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const onSubmitHandler = useCallback(
    (data: MediaFileCampaign) => {
      mutateCreate(data, {
        onError,
        onSuccess: (data) => {
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Campanha salva com sucesso'
          });
          navigate(`/media-manager/campaigns/${data.id}`);
        }
      });
    },
    [addToast, mutateCreate, navigate, onError]
  );

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: screenOrientationDisplay(ScreenOrientation.PORTRAIT)
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: screenOrientationDisplay(ScreenOrientation.LANDSCAPE)
    }
  ];
  const typeOptionsScreen = [
    { value: CampaignLayout.FULL_SCREEN, label: <LabelFullScreen /> },
    {
      value: CampaignLayout.HORIZONTALLY_SPLIT,
      label: <LabelHorizontallySplit />
    },
    { value: CampaignLayout.VERTICALLY_SPLIT, label: <LabelVerticallySplit /> },
    { value: CampaignLayout.HALF_SCREEN, label: <LabelHalfSplit /> }
  ];

  return (
    <div className="bg-white p-2 h-100">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center bg-white mb-2">
          <Col className="bg-white">
            <p className="mt-2 d-flex align-items-center">
              <Link
                to={'/media-manager/campaigns'}
                className="btn btn-link text-dark text-decoration-none me-2"
              >
                <Icon icon="chevron-left" />
              </Link>
              <span className="h5 fw-bolder mb-0">Campanha</span>
            </p>
          </Col>
          <Col className="text-end">
            <Button color="primary" icon="floppy">
              Salvar
            </Button>
          </Col>
        </Row>
        <Row>
          <p className="h5 fw-bolder">Configurações da campanha</p>
          <hr className="mb-4" />
          <TextField label="Nome" name="name" className="w-50" />
          <RadioChoicesFiled
            label="Selecione o tipo de tela:"
            name="layoutType"
            options={typeOptionsScreen}
            classNameRadio="d-flex"
          />
          <RadioChoicesFiled
            label="Selecione a orientação da imagem:"
            name="screenOrientation"
            options={typeOptionsOrientation}
            classNameRadio="d-flex"
          />
        </Row>
      </Form>
    </div>
  );
};

export default CampaignCreatePage;
