import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import MediaFile from 'models/MediaFile';
import env from 'config/env';
import MediaCampaignPosition from 'enum/MediaCampaignPosition';
import ScreenOrientation from 'enum/ScreenOrientation';
import CampaignLayout from 'enum/CampaignLayout';
import ptBr from './ptBr.json';
import { MediaEditor as MediaEditorTypeRef } from 'types/Cloudinary';
import classnames from 'classnames';
import logo from 'assets/images/logo.webp';
import { Spinner } from 'reactstrap';
import { useApiPut } from 'hooks/useApi';
import { useToast } from 'hooks/useToast';

export type EditedMedia = {
  url: string;
  cloudinaryJson: any;
};

type MediaEditorProps = {
  onFinish: (
    mediaFile: MediaFile,
    mediaEdited: EditedMedia,
    position?: MediaCampaignPosition
  ) => void;
  onClose?: () => void;
};

type ShowConfig = {
  orientation: ScreenOrientation;
  position?: MediaCampaignPosition;
  layout?: CampaignLayout;
  width?: number;
  height?: number;
};

export type MediaEditorRef = {
  showEditor: (mediaFile: MediaFile, config: ShowConfig) => void;
};
const WIDTH = 1920;
const HEIGHT = 1080;

const calculateAspectRatio = (width: number, height: number) => {
  const calculateGreatestCommonDivisor: (a: number, b: number) => number = (
    a,
    b
  ) => (b === 0 ? a : calculateGreatestCommonDivisor(b, a % b));
  const ratio = calculateGreatestCommonDivisor(width, height);
  return `${width / ratio}:${height / ratio}`;
};

const MediaEditor = forwardRef<MediaEditorRef, MediaEditorProps>(
  ({ onFinish, onClose }, outerRef) => {
    const { mutate } = useApiPut<{ id: string; uploadedCloudinary?: boolean }>(
      '/media-manager/medias'
    );
    const { addToast } = useToast();
    const [showModal, setShowModal] = useState(false);

    const calcAspectRatio = useCallback(
      ({
        orientation,
        position,
        layout,
        width = WIDTH,
        height = HEIGHT
      }: ShowConfig) => {
        let orientedWidth =
          orientation === ScreenOrientation.LANDSCAPE ? width : height;
        let orientedHeight =
          orientation === ScreenOrientation.LANDSCAPE ? height : width;
        if (layout === CampaignLayout.HALF_SCREEN) {
          orientedWidth = orientedWidth / 2;
        } else if (layout === CampaignLayout.HORIZONTALLY_SPLIT) {
          orientedHeight =
            position === MediaCampaignPosition.B1
              ? orientedHeight * 0.7
              : orientedHeight * 0.3;
        } else if (layout === CampaignLayout.VERTICALLY_SPLIT) {
          orientedWidth =
            position === MediaCampaignPosition.B1
              ? orientedWidth * 0.7
              : orientedWidth * 0.3;
        }
        return {
          label: 'Resolução base',
          width: orientedWidth,
          aspectRatio: calculateAspectRatio(orientedWidth, orientedHeight)
        };
      },
      []
    );

    const uploadMediaToCloudinary = useCallback(
      async (media: MediaFile): Promise<MediaFile> => {
        return new Promise((resolve, reject) => {
          mutate(
            { id: `${media.id}/cloudinary` },
            { onSuccess: resolve, onError: reject }
          );
        });
      },
      [mutate]
    );

    const handleOnClose = useCallback(() => {
      setShowModal(false);
      onClose?.();
    }, [onClose]);

    const handleOnFinish = useCallback(
      (
        mediaFile: MediaFile,
        position: MediaCampaignPosition | undefined,
        data: any
      ) => {
        const url = data.assets[0].downloadUrl;
        const transformation = data.transformation;
        onFinish(
          mediaFile,
          {
            url,
            cloudinaryJson: { transformation }
          },
          position
        );
        setShowModal(false);
      },
      [onFinish]
    );

    const subscribeEvents = useCallback(
      (
        editor: MediaEditorTypeRef,
        mediaFile: MediaFile,
        position?: MediaCampaignPosition
      ) => {
        try {
          editor.on('export', (data) => {
            handleOnFinish(mediaFile, position, data);
            editor.destroy();
          });
          editor.on('close', () => {
            handleOnClose();
            editor.destroy();
          });
        } catch (e) {
          console.error(e);
        }
      },
      [handleOnClose, handleOnFinish]
    );

    const handleOnShowCloudinaryEditor = useCallback(
      (
        editor: MediaEditorTypeRef,
        mediaFile: MediaFile,
        config: ShowConfig
      ) => {
        if (!mediaFile.id) return;

        const type = mediaFile.mineType?.split('/')[0] || 'image';
        const ratioConfig = calcAspectRatio(config);
        editor.update({
          cloudName: env.CLOUDINARY_NAME || '',
          publicIds: [
            {
              publicId: mediaFile.id,
              resourceType: type
            }
          ],
          language: {
            locale: 'pt_BR',
            messages: {
              pt_BR: ptBr
            }
          },
          theme: {
            logo: `${env.FE_URL}${logo}`
          },
          video: {
            steps: ['trim'],
            transformation: [
              { background: 'black' },
              { crop: 'fill' },
              { aspectRatio: ratioConfig.aspectRatio },
              { width: ratioConfig.width }
            ]
          },
          image: {
            steps: ['resizeAndCrop', 'textOverlays', 'export'],
            resizeAndCrop: {
              presets: [calcAspectRatio(config)],
              interactiveCrop: true,
              aspectRatioLock: true,
              toggleAspectRatio: false
            },
            export: {
              quality: ['auto'],
              download: false,
              share: false
            }
          }
        });
        editor.show();
      },
      [calcAspectRatio]
    );

    const showEditor = useCallback(
      async (mediaFile: MediaFile, config: ShowConfig) => {
        if (!mediaFile.id) return;
        setShowModal(true);
        try {
          const fileUploaded = await uploadMediaToCloudinary(mediaFile);
          if (!fileUploaded.uploadedCloudinary) {
            addToast({
              message: 'Não foi possível carregar o arquivo para o edição',
              color: 'danger',
              icon: 'alert-circle'
            });
            setShowModal(false);
            return;
          }
          const editor = cloudinary.mediaEditor({
            appendTo: `#cloudinary-media-editor-container`
          });
          subscribeEvents(editor, mediaFile, config.position);
          handleOnShowCloudinaryEditor(editor, fileUploaded, config);
        } catch (e) {
          console.error(e);
          addToast({
            message: 'Não foi possível carregar o arquivo para o edição',
            color: 'danger',
            icon: 'alert-circle'
          });
          setShowModal(false);
        }
      },
      [
        addToast,
        handleOnShowCloudinaryEditor,
        subscribeEvents,
        uploadMediaToCloudinary
      ]
    );

    useImperativeHandle(outerRef, () => {
      return {
        showEditor
      };
    });

    return (
      <div
        id="media-editor-container"
        className={classnames(
          'position-absolute vw-100 vh-100 d-flex justify-content-center align-items-center top-0 start-0 bg-white z-3',
          {
            'd-none': !showModal
          }
        )}
      >
        <Spinner
          color="primary"
          className={classnames('position-absolute top-50 start-50 z-0')}
        />
        <div
          id="cloudinary-media-editor-container"
          className={classnames('w-75 h-75 shadow z-1')}
        />
      </div>
    );
  }
);

export default MediaEditor;
