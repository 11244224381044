import React, { useEffect } from 'react';
import { Template1 } from './InternalsNewsTemplates';
import Button from 'components/Button';
import InternalsNews from 'models/InternalsNews';

type InternalsNewsPreviewProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onEnded?: () => void;
  news: InternalsNews;
  className?: string;
  duration?: number;
};

const InternalsNewsPreview = ({
  isOpen,
  onClose,
  onEnded,
  news,
  className,
  duration = 5
}: InternalsNewsPreviewProps) => {
  const second = 1000;

  useEffect(() => {
    if (isOpen && onEnded) {
      const timeout = setTimeout(() => {
        onEnded();
      }, duration * second);

      return () => clearTimeout(timeout);
    }
  }, [isOpen, onEnded, duration]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fullscreen-container">
      <Button
        className="close-button bg-transparent border-0"
        type="button"
        icon="x-circle-fill"
        onClick={onClose}
      />
      <Template1
        title={news.title}
        description={news.description!}
        logo={news.logo}
        photoCredit={news.photoCredit!}
        bgImage={news.bgImage!}
        news={news}
        color={news.color}
        qrCode={news.qrcodeUrl}
        className={className}
      />
    </div>
  );
};
export default InternalsNewsPreview;
