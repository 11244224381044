import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  ChangeEvent
} from 'react';
import TextField from 'components/TextField';
import Form from 'components/Form';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import RadioChoicesFiled from 'components/RadioChoicesField';
import { Label1 } from './InternalsNewsLabels';
import TemplateNews from 'enum/TemplateNews';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useUser from 'hooks/useUser';
import QRCode from 'qrcode.react';
import DateField from 'components/DateField';
import {
  useApiGet,
  useApiPost,
  useApiPut,
  useApiDelete,
  useApiPostFile
} from 'hooks/useApi';
import InternalsNews from 'models/InternalsNews';
import MediaGrid from 'components/MediaGrid';
import { withDragAndDropProvider } from 'contexts/dragAndDropProvider';
import NewsImageDrop from 'components/News/InternalsNewsImageDrop';
import Modal from 'components/Modal';
import { Loader } from 'react-bootstrap-typeahead';
import ScreenOrientation from 'enum/ScreenOrientation';
import SwitchField from 'components/SwitchField';
import ModalInternalsNewsPreview from 'components/ModalInternalsNewsPreview';
import FileField from 'components/FileField';
import moment from 'moment';
import InternalsNewsPreview from 'components/News/InternalsNewsPreview';

const schema = yup
  .object({
    title: yup.string().required('O título é obrigatório.'),
    description: yup.string().required('A descrição é obrigatória.'),
    photoCredit: yup.string().required('O crédito da foto é obrigatório.'),
    template: yup
      .mixed<TemplateNews>()
      .oneOf(Object.values(TemplateNews))
      .required(),
    qrcodeUrl: yup.string().url('Digite um URL válido.'),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    screenOrientation: yup
      .mixed<ScreenOrientation>()
      .oneOf(Object.values(ScreenOrientation))
      .required(),
    isActive: yup.boolean(),
    bgImage: yup.string().required('A imagem é obrigatória.'),
    logo: yup.mixed(),
    color: yup.string()
  })
  .required();

const NewsFormPage = () => {
  const params = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [qrcodeUrl, setQrcodeUrl] = useState('');
  const [mediaToPreview, setMediaToPreview] = useState<string>();

  const { data, isLoading: isFetching } = useApiGet<InternalsNews>(
    `/news/internals`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutateAsync: mutateCreate, isPending: isCreating } = useApiPost<
    InternalsNews
  >('/news/internals');
  const { mutateAsync: mutateUpdate, isPending: isUpdating } = useApiPut<
    InternalsNews
  >('/news/internals', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/news/internals',
    params.id
  );

  const { mutateAsync: uploadLogo } = useApiPostFile(`/news/internals`);

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const isSaving = isCreating || isUpdating;

  const internalsNews = params.id ? data : undefined;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.NEWS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.NEWS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.NEWS]?.includes(TypeAction.DELETE);
  }, [user]);
  const formMethods = useForm({
    resolver: yupResolver(schema)
  });
  const selectedScreenOrientation = useWatch({
    control: formMethods.control,
    name: 'screenOrientation',
    defaultValue: ScreenOrientation.LANDSCAPE
  });
  const selectedLogoFile = useWatch({
    control: formMethods.control,
    name: 'logo',
    defaultValue: null
  });

  const [
    selectedTemplate,
    selectedTitle,
    selectedDescription,
    selectedPhotoCredit,
    selectedBgImage,
    selectedColor,
    selectedQrCodeUrl
  ] = useWatch({
    control: formMethods.control,
    name: [
      'template',
      'title',
      'description',
      'photoCredit',
      'bgImage',
      'color',
      'qrcodeUrl'
    ]
  });

  const onError = useFieldSetErrors(formMethods.setError);
  const { addToast } = useToast();

  const toISOString = (date: string): string => {
    return new Date(date).toISOString();
  };

  const onSubmitHandler = useCallback(
    async (data: InternalsNews) => {
      try {
        const { logo, ...dataWithoutLogo } = data;

        const updatedData = {
          ...dataWithoutLogo,
          startDate: toISOString(data.startDate!),
          endDate: toISOString(data.endDate!),
          mediaFile: data.mediaFile?.id
        };

        const result = await mutate(updatedData as any);

        if (selectedLogoFile) {
          const formData = new FormData();
          formData.append('logo', selectedLogoFile);
          formData.append('id', `${result.id}/logo`);

          await uploadLogo(formData as any);

          addToast({
            color: 'success',
            icon: 'check',
            message: 'Logo salva com sucesso'
          });
          navigate('/news/internals');
        }
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Notícia salva com sucesso'
        });
        navigate('/news/internals');
      } catch (error) {
        console.error('Erro ao chamar mutate:', error);
        onError(error);
        addToast({
          color: 'danger',
          icon: 'error',
          message: 'Erro ao salvar notícia'
        });
      }
    },
    [mutate, uploadLogo, selectedLogoFile, addToast, navigate, onError]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir notícia'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Notícia excluída com sucesso'
        });
        navigate('/news/internals');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const typeOptionsTemplate = [
    { value: TemplateNews.TEMPLATE_UM, label: <Label1 /> }
  ];

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    },
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    }
  ];

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQrcodeUrl(event.target.value);
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    formMethods.setValue('logo', file as Blob);
  };

  const toDateInputValue = (date: string): string => {
    return moment(date).format('YYYY-MM-DD');
  };

  const logoSrc = useMemo(() => {
    return selectedLogoFile
      ? URL.createObjectURL(new Blob([selectedLogoFile]))
      : internalsNews?.logo;
  }, [selectedLogoFile, internalsNews?.logo]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = () => {
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
  };

  const aspectRatioClass =
    selectedScreenOrientation === ScreenOrientation.PORTRAIT
      ? 'aspect-ratio-box-3-4-news'
      : 'aspect-ratio-box-4-3-news';

  useEffect(() => {
    if (internalsNews) {
      formMethods.reset({
        title: internalsNews.title,
        description: internalsNews.description,
        photoCredit: internalsNews.photoCredit,
        template: internalsNews.template,
        qrcodeUrl: internalsNews.qrcodeUrl,
        color: internalsNews.color,
        startDate: toDateInputValue(internalsNews.startDate!),
        endDate: toDateInputValue(internalsNews.endDate!),
        screenOrientation: internalsNews.screenOrientation,
        isActive: internalsNews.isActive,
        bgImage: internalsNews.bgImage,
        mediaFile: internalsNews.mediaFile
      } as any);
    }
  }, [internalsNews, formMethods]);

  return (
    <div className="bg-white p-2 h-100">
      <Form onSubmit={onSubmitHandler} {...formMethods}>
        <Row className="d-flex align-items-center bg-white mb-2">
          <Col className="bg-white">
            <p className="mt-2 d-flex align-items-center">
              <Link
                to={'/news/internals'}
                className="btn btn-link text-dark text-decoration-none me-2"
              >
                <Icon icon="chevron-left" />
              </Link>
              <span className="h5 fw-bolder mb-0">Notícias Internas</span>
            </p>
          </Col>
          <Col className="text-end">
            {params.id && userCanDelete && (
              <Button
                type="button"
                color="danger"
                icon="trash"
                className="me-2"
                loading={isDeleting}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Excluir
              </Button>
            )}
            {userCanPerformAction && (
              <Button color="primary" icon="floppy" loading={isSaving}>
                Salvar
              </Button>
            )}
          </Col>
        </Row>
        {isFetching ? (
          <Loader />
        ) : (
          <Row>
            <p className="h5 fw-bolder">Conteúdo da Notícia</p>
            <hr className="mb-4" />
            <RadioChoicesFiled
              label="Selecione o template:"
              name="template"
              options={typeOptionsTemplate}
              classNameRadio="d-flex"
            />
            <div className="mt-2">
              <SwitchField
                name="isActive"
                label="Ativo"
                disabled={!userCanPerformAction}
                checked={internalsNews?.isActive}
              />
            </div>
            <div className="mt-2">
              <RadioChoicesFiled
                label="Orientação:"
                name="screenOrientation"
                options={typeOptionsOrientation}
                classNameRadio="d-flex"
              />
            </div>
            <TextField label="Título:" name="title" className="w-50" />

            <>
              <TextField
                label="Descrição:"
                name="description"
                placeholder="Escreva o texto da notícia aqui"
                type="textarea"
                className="w-50"
              />
              <TextField
                label="Créditos da foto:"
                name="photoCredit"
                className="w-50"
              />
              <TextField
                label="URL para gerar QR Code:"
                name="qrcodeUrl"
                className="w-50"
                onChange={handleUrlChange}
              />
              {params.id && qrcodeUrl && (
                <Row className="my-2">
                  <Col>
                    <p className="h6">QR Code para o URL:</p>
                    <QRCode value={internalsNews?.qrcodeUrl!} />
                  </Col>
                </Row>
              )}
              <TextField
                label="Selecione a cor:"
                name="color"
                className="w-25 h-75 mb-1"
                type="color"
              />
              <p className="mt-4">Período de Veiculação:</p>
              <div className="d-flex">
                <div>
                  <DateField
                    label="Data de início:"
                    name="startDate"
                    className="w-100"
                  />
                </div>
                <div className="ms-4">
                  <DateField
                    label="Data de término:"
                    name="endDate"
                    className="w-100"
                  />
                </div>
              </div>
              <div>
                <p className="mt-2">Imagem da Logo: </p>
                <div className="d-flex align-items-center">
                  <FileField
                    name="logo"
                    accept="image/*"
                    type="file"
                    onChange={handleLogoChange}
                  />
                  {selectedLogoFile || internalsNews?.logo ? (
                    <img
                      className="ms-2 mb-2"
                      width={100}
                      src={logoSrc}
                      alt="Preview"
                    />
                  ) : null}
                </div>
              </div>
              <Row>
                <Col md={6}>
                  <MediaGrid title="Gerenciamento de Mídias" />
                </Col>
                <Col>
                  <div className="mt-2 ms-2">
                    <p className="mb-2 d-flex align-items-center justify-content-between">
                      <span className="h5 fw-bolder">Imagem da Notícia</span>
                      <Button
                        className="white ms-2 border-0"
                        color="danger"
                        type="button"
                        onClick={() => openPreview()}
                      >
                        <Icon icon="play-btn-fill" className="me-3" />
                        Reproduzir Notícia
                      </Button>
                    </p>
                    <br />
                    <br />
                    <hr />
                    <p className="mb-3 text-body-secondary">
                      <Icon icon="house-door" className="me-1" /> Imagem
                    </p>
                    <NewsImageDrop
                      nameEditedFile="bgImage"
                      nameMediaFile="mediaFile"
                      onClick={(media: string) => setMediaToPreview(media)}
                      orientation={selectedScreenOrientation}
                    />
                  </div>
                </Col>
              </Row>
            </>
          </Row>
        )}
      </Form>
      <ModalInternalsNewsPreview
        editedMediaPreview={mediaToPreview!}
        isOpen={!!mediaToPreview}
        onClose={() => setMediaToPreview(undefined)}
      />
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir a notícia? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
      <InternalsNewsPreview
        isOpen={isPreviewOpen}
        onClose={closePreview}
        news={{
          ...internalsNews,
          template: selectedTemplate || internalsNews?.template,
          title: selectedTitle || internalsNews?.title!,
          description: selectedDescription || internalsNews?.description!,
          photoCredit: selectedPhotoCredit || internalsNews?.photoCredit!,
          bgImage: selectedBgImage || internalsNews?.bgImage!,
          color: selectedColor || internalsNews?.color,
          qrcodeUrl: selectedQrCodeUrl || internalsNews?.qrcodeUrl,
          logo: logoSrc
        }}
        className={aspectRatioClass}
      />
    </div>
  );
};

export default withDragAndDropProvider(NewsFormPage);
