import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

type ModalInternalsNewsPreviewProps = {
  editedMediaPreview?: string;
  isOpen: boolean;
  onClose: () => void;
};

const ModalInternalsNewsPreview = ({
  isOpen,
  onClose,
  editedMediaPreview
}: ModalInternalsNewsPreviewProps) => {
  if (!editedMediaPreview) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      size="lg"
      data-testid="modal-internals-news-preview"
    >
      <ModalHeader toggle={onClose}>Preview Imagem Editada</ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center flex-column py-5 modal-body-media-preview">
        <img
          src={editedMediaPreview}
          alt="Imagem editada"
          className="mw-100 mh-100 w-auto h-100 d-block m-auto"
        />
      </ModalBody>
    </Modal>
  );
};
export default ModalInternalsNewsPreview;
