import React, { PropsWithChildren, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import SessionContext from 'contexts/session';
import PageLoader from 'components/PageLoader';

type AuthenticatedRequiredProps = PropsWithChildren<{
  loginPath: string;
}>;
const AuthenticatedRequired = ({
  children,
  loginPath
}: AuthenticatedRequiredProps) => {
  const { user, userChecking } = useContext(SessionContext);
  return (
    <>
      {userChecking ? (
        <PageLoader />
      ) : !user ? (
        <Navigate to={loginPath} />
      ) : (
        children
      )}
    </>
  );
};

export default AuthenticatedRequired;
