import React, { useState, useMemo } from 'react';
// @ts-ignore
import { useDrag } from 'react-dnd';
import { Campaign } from 'models/MediaFileCampaign';
import { campaignLayoutDisplay } from 'enum/CampaignLayout';
import { screenOrientationDisplay } from 'enum/ScreenOrientation';
import { Table } from 'reactstrap';
import Button from 'components/Button';
import PlayerCampaignPreview from 'components/Campaign/PlayerCampaignPreview';

type CampaignsItemProps = {
  campaign: Campaign;
};

const CampaignsItem = ({ campaign }: CampaignsItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'Campaign',
    item: { id: campaign.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const handleCampaignContinuosOnEnded = () => {
    setIsPreviewOpen(true);
  };

  const totalDuration = useMemo(() => {
    return campaign.medias?.reduce(
      (total, media) => total + (media.duration || 0),
      0
    );
  }, [campaign.medias]);

  return (
    <tr ref={drag}>
      <Table>
        {!isDragging ? (
          <tr className="campaign-medias-list-item">
            <td>
              <tr className="d-flex justify-content-between align-items-center">
                <td className="w-100 me-4">
                  <small>Nome: </small>
                  <br />
                  <b>{campaign.name}</b>
                </td>
                <td className="w-100 mx-4">
                  <small>Duração: </small>
                  <br />
                  <b>{totalDuration} segundos</b>
                </td>
                <td className="w-100 mx-4">
                  <small>Layout: </small>
                  <br />
                  <b>{campaignLayoutDisplay(campaign.layoutType)}</b>
                </td>
                <td className="w-100 mx-4">
                  <small>Orientação: </small>
                  <br />
                  <b>{screenOrientationDisplay(campaign.screenOrientation)}</b>
                </td>
                <td className="ms-4">
                  <small>Campanha: </small>
                  <br />
                  <Button
                    type="button"
                    color="transparent"
                    icon="play-btn-fill"
                    onClick={() => openPreview(campaign)}
                  />
                </td>
              </tr>
            </td>
          </tr>
        ) : (
          <tr className="campaign-medias-list-item">
            <td className="bg-dark-subtle"></td>
          </tr>
        )}
      </Table>
      {selectedCampaign && (
        <PlayerCampaignPreview
          isOpen={isPreviewOpen}
          onClose={closePreview}
          campaign={selectedCampaign}
          onEnded={handleCampaignContinuosOnEnded}
        />
      )}
    </tr>
  );
};

export default CampaignsItem;
