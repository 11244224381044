import React, { PropsWithChildren, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import SessionContext from 'contexts/session';
import PageLoader from 'components/PageLoader';

type NonAuthenticatedRequiredProps = PropsWithChildren<{
  rootPath?: string;
}>;
const NonAuthenticatedRequired = ({
  children,
  rootPath
}: NonAuthenticatedRequiredProps) => {
  const { user, userChecking } = useContext(SessionContext);
  return (
    <>
      {userChecking ? (
        <PageLoader />
      ) : user ? (
        <Navigate to={rootPath || '/'} />
      ) : (
        children
      )}
    </>
  );
};

export default NonAuthenticatedRequired;
