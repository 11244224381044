import { MediaFileCampaign } from 'models/MediaFileCampaign';
import React, { useCallback, useState, useMemo } from 'react';
// @ts-ignore
import { useDrag, useDrop } from 'react-dnd';
import { Table, Input } from 'reactstrap';
import { useApiPut, useInvalidateQuery, useApiDelete } from 'hooks/useApi';
import { useToast } from 'hooks/useToast';
import Button from 'components/Button';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useUser from 'hooks/useUser';
import Icon from 'components/Icon';
import { startsWith } from 'lodash';
import Modal from 'components/Modal';

type CampaignItemProps = {
  dragAndDropKeyAccept: string;
  mediaCampaign: MediaFileCampaign;
  campaignId: string;
  index: number;
  onMediaReOrderFinish: () => void;
  onMediaReOrderPreview: (dragId: string, hoverId: string) => void;
  onClick?: (mediaCampaign: MediaFileCampaign) => void;
  isFirst?: boolean;
  isLast?: boolean;
  moveUp: (mediaId: string) => void;
  moveDown: (mediaId: string) => void;
};

type DragAndDropOrderType = { mediaId: string };
type DragOrderPreviewType = { isDragging: boolean };

const CampaignPositionItem = ({
  mediaCampaign,
  onMediaReOrderFinish,
  onMediaReOrderPreview,
  dragAndDropKeyAccept,
  onClick,
  campaignId,
  index,
  isFirst,
  isLast,
  moveUp,
  moveDown
}: CampaignItemProps) => {
  const user = useUser();
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [editedTime, setEditedTime] = useState(mediaCampaign.duration || 0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate, isPending } = useApiPut<MediaFileCampaign>(
    `/media-manager/campaigns/${campaignId}/medias/${mediaCampaign.id}`
  );
  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    `/media-manager/campaigns/${campaignId}/medias/${mediaCampaign.id}`
  );
  const userCanEdit = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.UPDATE
    );
  }, [user]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.CAMPAIGNS]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const handleTimeClick = useCallback(
    (duration: number) => {
      if (!userCanEdit) return;
      setIsEditingTime(true);
      setEditedTime(duration);
    },
    [userCanEdit]
  );
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTime(parseInt(e.target.value));
  };

  const { addToast } = useToast();

  const { invalidate } = useInvalidateQuery(['/media-manager/campaigns']);

  const handleTimeSave = useCallback(() => {
    mutate(
      {
        duration: editedTime,
        order: mediaCampaign.order,
        active: mediaCampaign.active
      },
      {
        onSuccess: () => {
          invalidate();
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Tempo atualizado com sucesso.'
          });
        },
        onError: (error: any) => {
          const { errors } = error || ({} as any);
          const message =
            errors?.status === 422 ? errors?.detail?.duration : errors?.detail;
          addToast({
            icon: 'exclamation-triangle',
            color: 'danger',
            message: message || 'Erro ao atualizar o tempo.'
          });
        }
      }
    );
    setIsEditingTime(false);
  }, [
    editedTime,
    mutate,
    mediaCampaign.order,
    mediaCampaign.active,
    addToast,
    invalidate
  ]);

  const handleTimeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleTimeSave();
    } else if (e.key === 'Escape') {
      setIsEditingTime(false);
    }
  };

  const [{ isDragging }, drag] = useDrag<
    DragAndDropOrderType,
    DragAndDropOrderType,
    DragOrderPreviewType
  >(() => ({
    type: dragAndDropKeyAccept,
    item: { mediaId: mediaCampaign.id! },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const [, drop] = useDrop<DragAndDropOrderType>(
    {
      accept: dragAndDropKeyAccept,
      hover(item) {
        onMediaReOrderPreview(item.mediaId, mediaCampaign.id!);
      },
      drop() {
        onMediaReOrderFinish();
      }
    },
    [onMediaReOrderPreview, mediaCampaign.id, onMediaReOrderFinish]
  );

  const handleMediaClick = useCallback(() => {
    onClick?.(mediaCampaign);
  }, [mediaCampaign, onClick]);

  const handleOnDelete = useCallback(() => {
    if (!userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir a mídia.'
        });
      },
      onSuccess: () => {
        invalidate();
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Mídia excluída com sucesso'
        });
      }
    });
  }, [addToast, mutateDelete, userCanDelete, invalidate]);

  const handleMoveUp = useCallback(() => {
    moveUp(mediaCampaign.id!);
    onMediaReOrderFinish();
  }, [moveUp, mediaCampaign.id, onMediaReOrderFinish]);

  const handleMoveDown = useCallback(() => {
    moveDown(mediaCampaign.id!);
    onMediaReOrderFinish();
  }, [moveDown, mediaCampaign.id, onMediaReOrderFinish]);

  return (
    <tr ref={drop}>
      <td ref={drag}>
        <Table>
          {!isDragging ? (
            <tr className="campaign-medias-list-item">
              <td>
                <div className="d-flex flex-column">
                  {isFirst ? null : (
                    <Button
                      color="transparent"
                      type="button"
                      className="border-0 p-0 button-order"
                      icon="caret-up-square"
                      onClick={handleMoveUp}
                    />
                  )}
                  {isLast ? null : (
                    <Button
                      color="transparent"
                      type="button"
                      className="border-0 p-0 button-order"
                      icon="caret-down-square"
                      onClick={handleMoveDown}
                    />
                  )}
                </div>
              </td>
              <td>
                <small>Ordem de execução: </small>
                <br />
                <b>{index + 1}</b>
              </td>
              <td>
                <small>Tempo: </small>
                <br />
                {startsWith(mediaCampaign.mediaFile?.mineType, 'image') &&
                  (!isEditingTime ? (
                    <span
                      role="button"
                      onClick={() =>
                        handleTimeClick(mediaCampaign?.duration || 0)
                      }
                    >
                      <b>{mediaCampaign?.duration} segundos</b>
                      {userCanEdit && <Icon icon="pencil" className="ms-1" />}
                    </span>
                  ) : (
                    <span>
                      <Input
                        className="w-50"
                        type="number"
                        value={editedTime}
                        onChange={handleTimeChange}
                        onKeyDown={handleTimeKeyDown}
                      />
                      <span className="d-flex justify-content-end w-50">
                        <Button
                          type="button"
                          color="primary"
                          className="mt-1 ps-1 pb-1 pt-0 pe-0"
                          icon="check-lg"
                          loading={isPending}
                          onClick={handleTimeSave}
                        ></Button>
                        <Button
                          type="button"
                          color="danger"
                          className="mt-1 ms-1 ps-1 pb-1 pt-0 pe-0"
                          icon="x-lg"
                          onClick={() => setIsEditingTime(false)}
                        ></Button>
                      </span>
                    </span>
                  ))}
                {startsWith(mediaCampaign.mediaFile?.mineType, 'video') && (
                  <b>{mediaCampaign?.duration} segundos</b>
                )}
              </td>
              <td>
                <div
                  role="button"
                  onClick={handleMediaClick}
                  className="img-thumbnail"
                >
                  <img
                    alt={mediaCampaign.mediaFile?.name}
                    src={mediaCampaign.thumbnail}
                    className="m-auto d-block"
                  />
                </div>
              </td>
              <td>
                {userCanDelete && (
                  <Button
                    type="button"
                    color="danger"
                    loading={isDeleting}
                    icon="trash"
                    className="ms-4 border-0 pe-0 button-delete"
                    onClick={() => setShowDeleteConfirmation(true)}
                  ></Button>
                )}
              </td>
            </tr>
          ) : (
            <tr className="campaign-medias-list-item">
              <td colSpan={4} className="bg-dark-subtle"></td>
            </tr>
          )}
        </Table>
        <Modal
          primaryButtonAction={handleOnDelete}
          primaryButtonLabel="Excluir"
          title="Atenção!"
          isOpen={showDeleteConfirmation}
          showCloseButton={false}
          toggle={() => setShowDeleteConfirmation((x) => !x)}
          secondaryButtonAction={() => setShowDeleteConfirmation(false)}
          secondaryButtonLabel="Cancelar"
        >
          <p>
            Desaja realmente excluir a mídia? <br />
            <small>Essa alteração não poderá ser revertida.</small>
          </p>
        </Modal>
      </td>
    </tr>
  );
};

export default CampaignPositionItem;
