import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import Icon from 'components/Icon';
import DeviceGroup from 'models/DeviceGroup';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import SwitchField from 'components/SwitchField';
import CustomerCompanies from 'models/CustomerCompanies';
import Pagination from 'models/Pagination';
import AsyncTypeaheadField, { Option } from 'components/AsyncTypeaheadField';

type FormValue = {
  name: string;
  active: boolean;
  company: Option | null;
};

const yupSchema = yup
  .object({
    name: yup.string().required('O nome é obrigatório.'),
    active: yup.boolean().required(),
    company: yup.object().required('A empresa é obrigatória.')
  })
  .required();

const DeviceGroupFormPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const {
    data: companiesData,
    isLoading: companiesLoading,
    refetch: companiesRefetch
  } = useApiGet<Pagination<CustomerCompanies>>('/customer/companies');

  const { data, isLoading: isFetching } = useApiGet<DeviceGroup>(
    `/device/group`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost(
    '/device/group'
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut(
    '/device/group',
    params.id
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/device/group',
    params.id
  );

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.DELETE);
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const deviceGroups = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      company: null
    }
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const companiesDataOptions = useMemo(() => {
    return (
      companiesData?.items.map((company) => ({
        label: company.name,
        value: company.id
      })) ?? []
    );
  }, [companiesData]);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        company: data.company?.value!,
        name: data.name,
        active: data.active
      } as any;
      mutate(payload, {
        onError,
        onSuccess: () => {
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Grupo de dispositivo salvo com sucesso'
          });
          navigate('/device/group');
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() ||
            'Erro ao excluir o grupo de dispositivos'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Grupo de dispositivos excluído com sucesso'
        });
        navigate('/device/group');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  useEffect(() => {
    if (deviceGroups) {
      formMethods.reset({
        name: deviceGroups.name,
        active: deviceGroups.active,
        company: deviceGroups.company
          ? {
              label: deviceGroups.company.name,
              value: deviceGroups.company.id
            }
          : null
      } as any);
    }
  }, [deviceGroups, formMethods]);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/device/group'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Grupo de dispositivos</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <Row>
              <Col className="col-sm-12 col-md-6 offset-md-3">
                <TextField
                  name="name"
                  label="Nome:"
                  placeholder="Nome"
                  disabled={!userCanPerformAction}
                  defaultValue={deviceGroups?.name}
                />
                <AsyncTypeaheadField
                  isLoading={companiesLoading}
                  name="company"
                  label="Selecione a empresa:"
                  options={companiesDataOptions}
                  onSearch={(search: string) =>
                    companiesRefetch({
                      params: { search }
                    } as any)
                  }
                  disabled={!userCanPerformAction}
                  multiple={false}
                />
                <div className="mb-3">
                  <SwitchField
                    name="active"
                    label="Ativo"
                    disabled={!userCanPerformAction}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir o grupo de dispositivos? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};

export default DeviceGroupFormPage;
