import React from 'react';
import Button from 'components/Button';
import { Playlist } from 'models/Playlist';
import MediaItemPreview from './MediaItemPreview';
import classNames from 'classnames';
import ScreenOrientation from 'enum/ScreenOrientation';

type PlayerPlaylistPreviewProps = {
  playlist: Playlist;
  isOpen: boolean;
  onClose: () => void;
};

const PlayerPlaylistPreview = ({
  playlist,
  isOpen,
  onClose
}: PlayerPlaylistPreviewProps) => {
  if (!isOpen || !playlist?.items?.length) return null;

  const aspectRatioClass =
    playlist?.screenOrientation === ScreenOrientation.PORTRAIT
      ? 'aspect-ratio-box-3-4'
      : 'aspect-ratio-box-4-3';

  return (
    <div className="fullscreen-container">
      <Button
        className="close-button bg-transparent border-0"
        type="button"
        icon="x-circle-fill"
        onClick={onClose}
      />
      <div className={classNames('half-screen-container', aspectRatioClass)}>
        <MediaItemPreview
          items={playlist.items}
          isOpen={isOpen}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

export default PlayerPlaylistPreview;
