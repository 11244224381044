import { Button, Col, Row } from 'reactstrap';
import React, { useCallback, useContext, useMemo } from 'react';
import logo from 'assets/images/logo.webp';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiPost } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import SessionContext from 'contexts/session';
import { Link } from 'react-router-dom';
import PasswordField from 'components/PasswordField';
import { User } from 'models/User';

const LoginPage = () => {
  const { setUser } = useContext(SessionContext);
  const schema = useMemo(() => {
    return yup
      .object({
        username: yup.string().required('O email é obrigatório.'),
        password: yup.string().required('A senha é obrigatória.')
      })
      .required();
  }, []);
  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onError = useFieldSetErrors(methods.setError);

  const { mutate: loginMutation, isPending } = useApiPost<User>('/auth/login');
  const onSuccess = useCallback(
    (response: User) => {
      setUser(response);
    },
    [setUser]
  );
  const onSubmitHandler = useCallback(
    (data: any) => {
      loginMutation(data, { onError, onSuccess });
    },
    [loginMutation, onError, onSuccess]
  );

  return (
    <Row className="d-flex justify-content-center align-items-center vh-100">
      <Col md={4} className="d-flex flex-column mx-auto">
        <img src={logo} alt="logo" className="rounded mx-auto d-block" />
        <p className="py-2 text-center">
          Digite seu endereço de e-mail para entrar
        </p>
        <Form onSubmit={onSubmitHandler} {...methods}>
          <TextField type="text" name="username" placeholder="Email" />
          <PasswordField
            type="password"
            name="password"
            placeholder="Password"
          />
          <Button
            color={methods.formState.isValid ? 'primary' : 'secondary'}
            size="lg"
            className="w-100"
            disabled={isPending}
          >
            Entrar
          </Button>
        </Form>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className=" pt-4">
            <Link
              to={'/forgot-password'}
              className="text-decoration-none small"
            >
              Esqueci minha senha
            </Link>
          </p>
          <p className=" pt-4">
            <Link to={'/signup'} className="text-decoration-none small">
              Cadastre-se
            </Link>
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;
