import MediaFile from 'models/MediaFile';
import React, { useCallback, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import moment from 'moment';
import { useApiGet } from 'hooks/useApi';

type ModalMediasPreviewProps = {
  itemId: string;
  isOpen: boolean;
  onClose: () => void;
};
const ModalMediasPreview = ({
  itemId,
  isOpen,
  onClose
}: ModalMediasPreviewProps) => {
  const [refetchInterval, setRefetchInterval] = React.useState<number | false>(
    false
  );
  const { data: item } = useApiGet('/media-manager/medias', {
    id: itemId,
    refetchInterval: refetchInterval
  });
  const isImage = useCallback((mediaFile: MediaFile) => {
    return mediaFile.mineType?.startsWith('image');
  }, []);

  useEffect(() => {
    if (item?.isConverted === false) {
      setRefetchInterval(1000);
    } else {
      setRefetchInterval(false);
    }
  }, [item]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      {item ? (
        <>
          <ModalHeader toggle={onClose}>{item.name}</ModalHeader>
          <ModalBody className="d-flex justify-content-center align-items-center flex-column py-5 modal-body-media-preview">
            {isImage(item) ? (
              <img
                src={item.file}
                alt={item.name}
                className="mw-100 mh-100 w-auto h-auto d-block m-auto"
                aria-label={item.name}
              />
            ) : (
              <>
                {!item.isConverted ? (
                  <>
                    <div className="media-icon position-absolute d-flex justify-content-center align-items-center w-100 h-100">
                      <div className="text-center bg-white opacity-4 rounded p-4">
                        <Spinner /> <br />
                        <strong>Aguarde, vídeo sendo processado!</strong>
                      </div>
                    </div>
                    <img
                      alt={item.name}
                      src={item.thumbnail}
                      className="m-auto w-100"
                    />
                  </>
                ) : (
                  <video
                    controls
                    className="h-100 w-100"
                    autoPlay
                    aria-label={item.name}
                  >
                    <source src={item.file} type={item.mineType} />
                  </video>
                )}
              </>
            )}
            <ModalFooter>
              <p className="w-100 text-nowrap text-truncate mb-0 small">
                <small>
                  Enviado {moment(item.createdAt).calendar()} por{' '}
                  {item.createdBy?.firstName}
                </small>
              </p>
            </ModalFooter>
          </ModalBody>
        </>
      ) : (
        <ModalBody className="d-flex justify-content-center align-items-center">
          <Spinner color="primary">Loading...</Spinner>
        </ModalBody>
      )}
    </Modal>
  );
};

export default ModalMediasPreview;
