import React, { useCallback, useEffect } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import Icon from 'components/Icon';

type SearchInputProps = {
  onSearchTextChange: (search: string) => void;
  placeholder?: string;
  className?: string;
};
const MS_TO_WAIT_TYPING = 1000;
const SearchInput = ({
  onSearchTextChange,
  placeholder,
  className
}: SearchInputProps) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const timeoutRef = React.useRef<any>(null);

  const handleSearch = useCallback(
    (e: any) => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onSearchTextChange(e.target?.value ?? '');
      }, MS_TO_WAIT_TYPING);
    },
    [onSearchTextChange]
  );

  useEffect(() => {
    const input = ref.current;
    const timeout = timeoutRef.current;
    ref.current?.addEventListener('input', handleSearch);
    return () => {
      input?.removeEventListener('change', handleSearch);
      timeout && clearTimeout(timeout);
    };
  });

  const handleClearInput = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
      onSearchTextChange('');
    }
  }, [onSearchTextChange]);

  return (
    <InputGroup className={className}>
      <InputGroupText className="text-black-50">
        <Icon icon="search" width={20} height={20} />
      </InputGroupText>
      <Input
        id={'search'}
        type="text"
        placeholder={placeholder}
        innerRef={ref}
        defaultValue=""
      />
      <InputGroupText
        className="text-black-50"
        role="button"
        onClick={handleClearInput}
      >
        <Icon icon="x-circle-fill" width={15} height={15} />
      </InputGroupText>
    </InputGroup>
  );
};

export default SearchInput;
