import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import PageSizeSelector from 'components/PageSizeSelector';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import DateField from 'components/DateField';
import HistoryLog from 'models/HistoryLog';
import Form from 'components/Form';
import Button from 'components/Button';
import moment from 'moment';

type FilteredDataType = {
  pageSize: number;
  page: number;
  startDate?: string;
  endDate?: string;
};

const yupSchema = yup
  .object({
    startDate: yup.string().required(),
    endDate: yup.string().required()
  })
  .required();

const HistoryLogsListPage = () => {
  const [filteredData, setFilteredData] = React.useState<FilteredDataType>({
    pageSize: 10,
    page: 1
  });

  const { data: historyLogsListPage, isLoading } = useApiGet<
    Pagination<HistoryLog>
  >('/customer/logs/history', {
    params: filteredData
  });

  const handleSearchByDate = useCallback(
    (startDate: string, endDate: string) => {
      setFilteredData({ ...filteredData, startDate, endDate });
    },
    [filteredData]
  );

  const handlePageChange = useCallback((page: number) => {
    setFilteredData((prevState) => ({
      ...prevState,
      page
    }));
  }, []);

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Histórico de Logs</span>
          </p>
        </Col>
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <Form
            onSubmit={({ startDate, endDate }) =>
              handleSearchByDate(startDate, endDate)
            }
            {...formMethods}
          >
            <Row className="d-flex align-items-center">
              <Col>
                <DateField name="startDate" label="Data inicial" />
              </Col>
              <Col>
                <DateField name="endDate" label="Data final" />
              </Col>
              <Col>
                <Button type="submit" className="mt-4">
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {historyLogsListPage?.items.map((log) => (
            <tr key={log.timestamp}>
              <td>
                <small>Data e horário: </small>
                <br />
                <b>{moment(log.timestamp).format('LLL')}</b>
              </td>
              <td>
                <small>Ação: </small>
                <br />
                <b>{log.action}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Boolean(historyLogsListPage?.total === 1) ? (
        <p className="text-end">{historyLogsListPage?.total} ação encontrada</p>
      ) : (
        <p className="text-end">
          {historyLogsListPage?.total} ações encontradas
        </p>
      )}
      {historyLogsListPage && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={historyLogsListPage.totalPages}
          currentPage={historyLogsListPage.page}
        />
      )}
    </div>
  );
};

export default HistoryLogsListPage;
