import { Button as BButton, Spinner } from 'reactstrap';
import { ButtonProps } from 'reactstrap/types/lib/Button';
import React from 'react';
import Icon from 'components/Icon';

type Props = {
  loading?: boolean;
  icon?: string;
} & ButtonProps;

const Button = ({ loading, disabled, ...props }: Props) => {
  const icon = loading ? (
    <Spinner size="sm" />
  ) : (
    props.icon && <Icon icon={props.icon} className={`me-2`} />
  );
  return (
    <BButton {...props} disabled={loading || disabled}>
      {icon} {props.children}
    </BButton>
  );
};

export default Button;
