import React from 'react';
import { Col, Row } from 'reactstrap';
import TextField from 'components/TextField';
import { useApiGet } from 'hooks/useApi';
import Device from 'models/Device';
import { useForm, FormProvider } from 'react-hook-form';

const ResourcesPage = () => {
  const { data: device } = useApiGet<Device>(`/device`);

  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <div className="mt-4">
        <span className="h5 fw-bolder mb-0">Recursos do Dispositivo</span>
        <hr />
        <Row xs="2" className="d-flex align-items-center">
          <Col>
            <TextField
              name="so"
              label="S.O.:"
              placeholder="S.O."
              disabled={!device?.player?.so}
              defaultValue={device?.player?.so}
            />
          </Col>
          <Col>
            <TextField
              name="playerVersion"
              label="Versão Player:"
              placeholder="Versão Player"
              disabled={!device?.player?.playerVersion}
              defaultValue={device?.player?.playerVersion}
            />
          </Col>
          <Col>
            <TextField
              name="menRam"
              label="Tot. Memória RAM:"
              placeholder="Tot. Memória RAM"
              disabled={!device?.player?.menRam}
              defaultValue={device?.player?.menRam}
            />
          </Col>
          <Col>
            <TextField
              name="storageSize"
              label="Total HD:"
              placeholder="Total HD"
              disabled={!device?.player?.storageSize}
              defaultValue={device?.player?.storageSize}
            />
          </Col>
          <Col>
            <TextField
              name="storageUsed"
              label="Utilizado HD:"
              placeholder="Utilizado HD"
              disabled={!device?.player?.storageUsed}
              defaultValue={device?.player?.storageUsed}
            />
          </Col>
          <Col>
            <TextField
              name="brand"
              label="Marca do dispositivo:"
              placeholder="Marca do dispositivo"
              disabled={!device?.player?.brand}
              defaultValue={device?.player?.brand}
            />
          </Col>
          <Col>
            <TextField
              name="processor"
              label="Processador:"
              placeholder="Processador"
              disabled={!device?.player?.processor}
              defaultValue={device?.player?.processor}
            />
          </Col>
          <Col>
            <TextField
              name="ip"
              label="IP:"
              placeholder="IP"
              disabled={!device?.player?.ip}
              defaultValue={device?.player?.ip}
            />
          </Col>
          <Col>
            <TextField
              name="guid"
              label="GUID:"
              placeholder="GUID"
              disabled={!device?.player?.token}
              defaultValue={device?.player?.token}
            />
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
};

export default ResourcesPage;
