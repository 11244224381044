import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren
} from 'react';
import Device from 'models/Device';

type DeviceContextType = {
  selectedDevice: Device | null;
  setSelectedDevice: (device: Device | null) => void;
};

const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

export const DeviceProvider = ({ children }: PropsWithChildren<any>) => {
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);

  return (
    <DeviceContext.Provider value={{ selectedDevice, setSelectedDevice }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }
  return context;
};
