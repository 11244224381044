import React from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';

type ImagePreviewProps = {
  imageUrl?: string;
};

const ImagePreview = ({ imageUrl }: ImagePreviewProps) => {
  return (
    <div className={classNames('round-image-container')}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Preview"
          className={classNames('round-image')}
        />
      ) : (
        <Icon
          icon="person-circle"
          className={classNames('round-image', 'text-secondary')}
        />
      )}
    </div>
  );
};

export default ImagePreview;
