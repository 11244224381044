import MediaItem from 'components/MediaGrid/MediaItem';
import React, { useCallback, useState } from 'react';
import MediaFile from 'models/MediaFile';
import ModalMediasPreview from 'components/ModalMediasPreview';

type MediaFilesGridProps = {
  medias?: MediaFile[];
  onEditMedia: (media: MediaFile) => void;
  onGoToFolder: (parent: { name: string; id: string }) => void;
  reFetchMedias: () => void;
};
const MediaFilesGrid = ({
  medias,
  onGoToFolder,
  onEditMedia,
  reFetchMedias
}: MediaFilesGridProps) => {
  const [mediaToPreview, setMediaToPreview] = useState<MediaFile>();
  const handleMediaClick = useCallback(
    (media: MediaFile) => {
      if (media.isFolder) {
        onGoToFolder({ name: media.name!, id: media.id! });
      } else {
        setMediaToPreview(media);
      }
    },
    [onGoToFolder]
  );
  return (
    <>
      <div className="d-flex flex-row flex-wrap p-2 justify-content-center justify-content-sm-start">
        {medias?.map((media) => (
          <MediaItem
            key={media.id}
            mediaFile={media}
            onClick={handleMediaClick}
            onEdit={onEditMedia}
            reFetchMedias={reFetchMedias}
          />
        ))}
      </div>
      {medias && (
        <ModalMediasPreview
          itemId={mediaToPreview?.id!}
          isOpen={!!mediaToPreview}
          onClose={() => setMediaToPreview(undefined)}
        />
      )}
    </>
  );
};

export default MediaFilesGrid;
