import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { isArray } from 'lodash';

const useFieldSetErrors = (setError?: any) => {
  const formContext = useFormContext();

  return useCallback(
    (networkError: any) => {
      const realSetError = setError || formContext.setError;
      const { errors } = networkError || {};
      if (!errors) return;
      if (errors.status === 422) {
        Object.keys(errors.detail).forEach((name) =>
          realSetError(name, {
            type: 'manual',
            message: isArray(errors.detail[name])
              ? errors.detail[name].join(', ')
              : String(errors.detail[name])
          })
        );
      } else {
        realSetError('root.genericError', {
          type: String(errors.status),
          message: errors.detail
        });
      }
    },
    [setError, formContext?.setError]
  );
};

export default useFieldSetErrors;
