import { Col, Container, Row } from 'reactstrap';
import React from 'react';

const DashboardPageAdm = () => {
  return (
    <>
      <div>
        <Container fluid>
          <Row className="d-flex align-items-center">
            <Col className="col-sm-4 col-md-3 col-lg-2"></Col>
            <Col md="3">
              <p className="mt-2">
                <span className="h4 fw-bolder">Dashboard Administrativo</span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DashboardPageAdm;
