import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import MediaGrid from 'components/MediaGrid';
import classNames from 'classnames';
import CampaignListDragPage from './CampaignListDragPage';
import InternalsNewsListDragPage from './InternalsNewsListDragPage';

enum Pages {
  MEDIA_GRID = '1',
  CAMPAIGNS = '2',
  INTERNALS_NEWS = '3'
}

type PlaylistItemsPageProps = { playlistScreenOrientation: string };

const PlaylistItemsPage = ({
  playlistScreenOrientation
}: PlaylistItemsPageProps) => {
  const [activeTab, setActiveTab] = useState<string>(Pages.MEDIA_GRID);

  return (
    <div>
      <div>
        <Nav tabs className="mt-2">
          <NavItem className="cursor-pointer">
            <NavLink
              className={classNames({ active: activeTab === Pages.MEDIA_GRID })}
              onClick={() => setActiveTab(Pages.MEDIA_GRID)}
            >
              Mídias
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === Pages.CAMPAIGNS })}
              onClick={() => setActiveTab(Pages.CAMPAIGNS)}
            >
              Campanhas
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Pages.INTERNALS_NEWS
              })}
              onClick={() => setActiveTab(Pages.INTERNALS_NEWS)}
            >
              Notícias Internas
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="mt-2">
              <MediaGrid title="Gerenciamento de Mídias" />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="mt-2">
              <CampaignListDragPage
                screenOrientation={playlistScreenOrientation}
              />
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="mt-2">
              <InternalsNewsListDragPage
                screenOrientation={playlistScreenOrientation}
              />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default PlaylistItemsPage;
