import { Col, Row, Spinner } from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import PageSizeSelector from 'components/PageSizeSelector';
import { Campaign } from 'models/MediaFileCampaign';
import CampaignsItem from 'components/Campaign/CampaignsItem';

type CampaignListDragPageProps = {
  screenOrientation: string;
};

const CampaignListDragPage = ({
  screenOrientation
}: CampaignListDragPageProps) => {
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: campaignsData, isLoading } = useApiGet<Pagination<Campaign>>(
    '/media-manager/campaigns',
    {
      params: filteredData
    }
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const filteredCampaigns = useMemo(() => {
    return campaignsData?.items.filter(
      (campaign) => campaign.screenOrientation === screenOrientation
    );
  }, [campaignsData, screenOrientation]);

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Campanhas</span>
          </p>
        </Col>
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar campanha"
          />
        </Col>
        <Col className="text-end mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <div className="table-campaign-drag border">
        {isLoading && (
          <tr>
            <td colSpan={2} className="text-center p-5">
              <Spinner />
            </td>
          </tr>
        )}
        {filteredCampaigns?.map((campaign) => (
          <CampaignsItem key={campaign.id} campaign={campaign} />
        ))}
      </div>
      {!isLoading && campaignsData?.total && (
        <p className="text-end">
          {campaignsData?.total} campanha(s) encontrada(s)
        </p>
      )}
      {campaignsData && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={campaignsData.totalPages}
          currentPage={campaignsData.page}
        />
      )}
    </div>
  );
};

export default CampaignListDragPage;
