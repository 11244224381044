import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionContext from 'contexts/session';
import { ROUTES_PATHS } from 'navigations/routes';
import PageLoader from 'components/PageLoader';

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useContext(SessionContext);

  useEffect(() => {
    logout();
    navigate(ROUTES_PATHS.AUTH_PAGES.LOGIN);
  }, [logout, navigate]);

  return <PageLoader />;
};

export default Logout;
