import { Input, Label } from 'reactstrap';
import React from 'react';

type PageSizeSelectorProps = {
  pageSize?: number;
  onPageSizeChanged: (pageSize: number) => void;
};
const PageSizeSelector = ({
  pageSize,
  onPageSizeChanged
}: PageSizeSelectorProps) => {
  return (
    <div className="d-inline-block">
      <Label for="pageSize" className="d-inline-block me-2">
        Item por página
      </Label>
      <Input
        className="d-inline-block w-auto"
        id="pageSize"
        name="pageSize"
        type="select"
        value={String(pageSize || 10)}
        onChange={(e) => onPageSizeChanged(Number(e.target.value))}
      >
        <option value="10">10</option>
        <option value="30">30</option>
        <option value="50">50</option>
      </Input>
    </div>
  );
};

export default PageSizeSelector;
