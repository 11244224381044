import React, { useCallback, useRef } from 'react';
// @ts-ignore
import { useDrop } from 'react-dnd';
import { Card, Spinner } from 'reactstrap';
import InternalsNewsImageItem from './InternalsNewsImageItem';
import MediaFile from 'models/MediaFile';
import { useController } from 'react-hook-form';
import MediaEditor, {
  EditedMedia,
  MediaEditorRef
} from 'components/MediaEditor';
import ScreenOrientation from 'enum/ScreenOrientation';

type NewsImageDropProps = {
  isLoading?: boolean;
  onClick?: (mediaEdited: string) => void;
  nameEditedFile: string;
  nameMediaFile: string;
  orientation: ScreenOrientation;
};

const NewsImageDrop = ({
  isLoading,
  onClick,
  nameEditedFile,
  nameMediaFile,
  orientation
}: NewsImageDropProps) => {
  const refMediaEditor = useRef<MediaEditorRef>(null);
  const {
    field: { value: bgImageValue, onChange: onChangeBgImage },
    fieldState: { error: bgImageError }
  } = useController({
    name: nameEditedFile
  });
  const {
    field: { onChange },
    fieldState: { error }
  } = useController({
    name: nameMediaFile
  });

  const handleDrop = useCallback(
    (mediaDropped: MediaFile) => {
      if (mediaDropped.isFolder || !mediaDropped.mineType!.startsWith('image'))
        return;
      refMediaEditor?.current?.showEditor(mediaDropped, {
        orientation
      });
    },
    [orientation]
  );

  const handleOnFinish = useCallback(
    (mediaFile: MediaFile, mediaEdited: EditedMedia) => {
      onChange(mediaFile);
      const { url } = mediaEdited;
      onChangeBgImage(url);
    },
    [onChange, onChangeBgImage]
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'MediaItem',
    drop: handleDrop,
    canDrop: (mediaDropped: MediaFile) =>
      !mediaDropped.isFolder && mediaDropped.mineType!.startsWith('image'),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <>
      <div ref={drop} className="w-100 mb-4 position-relative">
        <Card color="light" className="playlist-medias-list">
          {bgImageValue ? (
            <InternalsNewsImageItem
              editedMedia={bgImageValue}
              onClick={onClick}
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <p>Arraste uma imagem aqui</p>
            </div>
          )}
          {isLoading && (
            <div className="position-absolute h-100 w-100 top-0 start-0 bg-grey opacity-1 z-3 d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          )}
          {isOver && canDrop && (
            <div className="position-absolute h-100 w-100 top-0 start-0 bg-success-subtle opacity-1 z-3 d-flex justify-content-center align-items-center">
              <p className="fw-bold text-black">Solte a imagem aqui!</p>
            </div>
          )}
          {isOver && !canDrop && (
            <div className="position-absolute h-100 w-100 top-0 start-0 bg-danger-subtle opacity-1 z-3 d-flex justify-content-center align-items-center">
              <p className="fw-bold text-black">
                Somente imagens são permitidas aqui!
              </p>
            </div>
          )}
        </Card>
        {error && (
          <div className="text-danger mt-2">
            <small>{error.message}</small>
          </div>
        )}
        {bgImageError && (
          <div className="text-danger mt-2">
            <small>{bgImageError.message}</small>
          </div>
        )}
      </div>
      <MediaEditor onFinish={handleOnFinish} ref={refMediaEditor} />
    </>
  );
};

export default NewsImageDrop;
