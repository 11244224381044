import AccessPolicy from 'models/AccessPolicy';
import { useFieldArray, useFormState } from 'react-hook-form';
import { useApiGet } from 'hooks/useApi';
import React, { useCallback, useMemo } from 'react';
import { Loader } from 'react-bootstrap-typeahead';
import { FormGroup, Input, Label, Table } from 'reactstrap';

type ResourcesFieldProps = {
  disabled?: boolean;
};

type ResourceChoice = { value: string; label: string };

type FormValues = {
  resources: AccessPolicy['resources'];
};
const ResourcesField = ({ disabled }: ResourcesFieldProps) => {
  const { append, fields, update } = useFieldArray<FormValues>({
    name: 'resources'
  });
  const { errors } = useFormState({ name: 'resources' });
  const error = errors?.resources;
  const { data: resourceList, isLoading: isFetching } = useApiGet<
    ResourceChoice[]
  >('/authorization/access-policies/resources');

  const values = useMemo(() => {
    return fields.reduce((acc, field, index) => {
      return {
        ...acc,
        [field.resource]: { ...field, index }
      };
    }, {} as { [key: string]: typeof fields[number] & { index: number } });
  }, [fields]);

  const handleItemChange = useCallback(
    (inputEvent: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = inputEvent.target;
      const [resource, action] = name.split('.');
      const field = values[resource];
      if (field) {
        update(field.index, {
          ...field,
          canRead:
            field.canUpdate || field.canDelete || field.canCreate || checked,
          [action]: checked
        });
      } else {
        append({
          resource,
          canRead: true,
          canUpdate: action === 'canUpdate' ? checked : false,
          canDelete: action === 'canDelete' ? checked : false,
          canCreate: action === 'canCreate' ? checked : false
        });
      }
    },
    [values, update, append]
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <FormGroup>
      <Label>Informe os recursos desejados</Label>
      {error && <p className="text-danger">{error.message?.toString()}</p>}
      <Table bordered>
        <thead>
          <tr>
            <th className="w-100">Recurso</th>
            <th className="text-center bg-gray-200">Ver</th>
            <th className="text-center bg-gray-200">Editar</th>
            <th className="text-center bg-gray-200">Inserir</th>
            <th className="text-center bg-gray-200">Remover</th>
          </tr>
        </thead>
        <tbody>
          {resourceList?.map(({ value, label }) => (
            <tr key={value}>
              <td>{label}</td>
              <td className="text-center bg-gray-200">
                <FormGroup switch>
                  <Label check hidden htmlFor={`${value}.canRead`}>
                    Ver {label}
                  </Label>
                  <Input
                    className="text-primary"
                    type="checkbox"
                    name={`${value}.canRead`}
                    onChange={handleItemChange}
                    checked={values[value]?.canRead}
                    disabled={
                      values[value]?.canUpdate ||
                      values[value]?.canDelete ||
                      values[value]?.canCreate ||
                      disabled
                    }
                    id={`${value}.canRead`}
                  />
                </FormGroup>
              </td>
              <td className="text-center bg-gray-200">
                <FormGroup switch>
                  <Label check hidden htmlFor={`${value}.canUpdate`}>
                    Editar {label}
                  </Label>
                  <Input
                    disabled={disabled}
                    type="checkbox"
                    name={`${value}.canUpdate`}
                    onChange={handleItemChange}
                    id={`${value}.canUpdate`}
                    checked={values[value]?.canUpdate}
                  />
                </FormGroup>
              </td>
              <td className="text-center bg-gray-200">
                <FormGroup switch>
                  <Label check hidden htmlFor={`${value}.canCreate`}>
                    Criar {label}
                  </Label>
                  <Input
                    disabled={disabled}
                    type="checkbox"
                    name={`${value}.canCreate`}
                    id={`${value}.canCreate`}
                    onChange={handleItemChange}
                    checked={values[value]?.canCreate}
                  />
                </FormGroup>
              </td>
              <td className="text-center bg-gray-200">
                <FormGroup switch>
                  <Label check hidden htmlFor={`${value}.canDelete`}>
                    Remover {label}
                  </Label>
                  <Input
                    disabled={disabled}
                    type="checkbox"
                    name={`${value}.canDelete`}
                    data-test-id={`${value}.canDelete`}
                    onChange={handleItemChange}
                    checked={values[value]?.canDelete}
                    id={`${value}.canDelete`}
                  />
                </FormGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </FormGroup>
  );
};

export default ResourcesField;
