import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useState } from 'react';
import SearchInput from 'components/SearchInput';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import AdminNotification from 'models/AdminNotification';
import Button from 'components/Button';
import ModalAdminImageNotification from 'components/ModalAdminImageNotification';
import classNames from 'classnames';

const AdminNotificationsListPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState({
    pageSize: 10,
    page: 1,
    search: ''
  });
  const { data: adminNotificationsData, isLoading } = useApiGet<
    Pagination<AdminNotification>
  >('/admin/notifications', {
    params: filteredData
  });

  const handleSearch = useCallback(
    (search: string) => {
      setFilteredData({ ...filteredData, search });
    },
    [filteredData]
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );
  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/admin/notifications/${id}`);
    },
    [navigate]
  );

  const [
    selectedAdminNotification,
    setSelectedAdminNotification
  ] = useState<AdminNotification | null>(null);

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const handleNotificationClick = useCallback(
    (notification: AdminNotification) => {
      setSelectedAdminNotification(notification);
      toggleModal();
    },
    []
  );
  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Notificações</span>
          </p>
        </Col>
        <Col className="text-end">
          <Link to={'/admin/notifications/create'} className="btn btn-primary">
            <Icon icon="plus" />
            Adicionar Novo
          </Link>
        </Col>
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <SearchInput
            onSearchTextChange={handleSearch}
            placeholder="Pesquisar notificações"
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />
      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {adminNotificationsData?.items.map((adminNotifications) => (
            <tr>
              <div className="d-flex d-flex justify-content-between align-items-center w-100">
                <div
                  className="d-flex justify-content-between align-items-center w-100"
                  key={adminNotifications.id}
                  role="button"
                  onClick={() => goToEdit(adminNotifications.id!)}
                >
                  <td className="w-100">
                    <small>Título: </small>
                    <br />
                    <b>{adminNotifications.title}</b>
                  </td>
                  <td className="w-100">
                    <small>Mensagem: </small>
                    <br />
                    <b className="text-truncate-wrap">
                      {adminNotifications.message}
                    </b>
                  </td>
                  <td className="w-100">
                    <small>Lida: </small>
                    <br />
                    <b>{adminNotifications.read ? 'Sim' : 'Não'}</b>
                  </td>
                </div>
                <td className={classNames('width-100')}>
                  {adminNotifications.image ? (
                    <>
                      <small>Imagem: </small>
                      <Button
                        type="button"
                        color="transparent"
                        icon="play-btn-fill"
                        onClick={() =>
                          handleNotificationClick(adminNotifications)
                        }
                      />
                    </>
                  ) : null}
                </td>
              </div>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedAdminNotification && (
        <ModalAdminImageNotification
          isOpen={modalOpen}
          toggle={toggleModal}
          adminNotification={selectedAdminNotification}
        />
      )}
      {Boolean(adminNotificationsData?.total) && (
        <p className="text-end">
          {adminNotificationsData?.total} grupo(s) de dispositivo(s)
          encontrado(s)
        </p>
      )}
      {adminNotificationsData && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={adminNotificationsData.totalPages}
          currentPage={adminNotificationsData.page}
        />
      )}
    </div>
  );
};

export default AdminNotificationsListPage;
