import { Col, Row, Spinner, Table } from 'reactstrap';
import React, { useCallback, useState } from 'react';
import { useApiGet } from 'hooks/useApi';
import Pagination from 'models/Pagination';
import PaginationNav from 'components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import PageSizeSelector from 'components/PageSizeSelector';
import License from 'models/License';
import { LicenseTypeLabel } from 'enum/LicenseType';
import moment from 'moment';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Customer from 'models/Customer';

type FilteredData = {
  pageSize: number;
  page: number;
  customer?: string;
};

const AdminLicensesListPage = () => {
  const navigate = useNavigate();
  const [customersSearch, setCustomersSearch] = useState('');
  const [openCustomerFilterPanel, setOpenCustomerFilterPanel] = useState(false);
  const [filteredData, setFilteredData] = React.useState<FilteredData>({
    pageSize: 10,
    page: 1
  });
  const { data: licences, isLoading } = useApiGet<Pagination<License>>(
    '/admin/licenses',
    {
      params: filteredData
    }
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setFilteredData({ ...filteredData, page });
    },
    [filteredData]
  );

  const goToEdit = useCallback(
    (id: string) => {
      navigate(`/admin/licenses/${id}`);
    },
    [navigate]
  );

  const { data: customers, isLoading: isLoadingCustomers } = useApiGet<
    Pagination<Customer>
  >(
    '/admin/customers',
    customersSearch
      ? {
          params: {
            search: customersSearch
          }
        }
      : undefined
  );

  return (
    <div className="bg-white p-2 h-100">
      <Row className="d-flex align-items-center bg-white mb-2">
        <Col className="bg-white">
          <p className="mt-2">
            <span className="h5 fw-bolder">Licenças</span>
          </p>
        </Col>
        <Col className="text-end">
          <Link to={'/admin/licenses/create'} className="btn btn-primary">
            <Icon icon="plus" />
            Adicionar Novas
          </Link>
        </Col>
      </Row>
      <Row className="d-flex align-items-center bg-white">
        <Col className="bg-white  mt-1" md="4">
          <AsyncTypeahead
            options={customers?.items || []}
            delay={500}
            onSearch={(query) => setCustomersSearch(query)}
            labelKey={'name'}
            clearButton
            placeholder="Pesquisar por cliente"
            isLoading={isLoadingCustomers}
            onChange={(selected) => {
              if (selected && selected.length) {
                const customer = selected[0] as Customer;
                setFilteredData({ ...filteredData, customer: customer.id });
              } else {
                setFilteredData({ ...filteredData, customer: undefined });
              }
              setOpenCustomerFilterPanel(false);
            }}
            onFocus={() => setOpenCustomerFilterPanel(true)}
            onBlur={() => setOpenCustomerFilterPanel(false)}
            onMenuToggle={setOpenCustomerFilterPanel}
            open={openCustomerFilterPanel}
          />
        </Col>
        <Col className="text-end  mt-1">
          <PageSizeSelector
            pageSize={filteredData?.pageSize}
            onPageSizeChanged={(pageSize) =>
              setFilteredData({ ...filteredData, pageSize })
            }
          />
        </Col>
      </Row>
      <hr />

      <Table responsive hover className="border">
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={2} className="text-center p-5">
                <Spinner />
              </td>
            </tr>
          )}
          {licences?.items.map((license) => (
            <tr
              className="d-flex justify-content-between align-items-center w-100"
              key={license.id}
              role="button"
              onClick={() => goToEdit(license.id!)}
            >
              <td className="w-100">
                <small>Cliente: </small>
                <br />
                <b>{license?.customer?.name}</b>
              </td>
              <td className="w-100">
                <small>Tipo: </small>
                <br />
                <b className="text-truncate-wrap">
                  {LicenseTypeLabel[license.licenseType]}
                </b>
              </td>
              <td className="w-100">
                <small>Ativa: </small>
                <br />
                <b>{license.active ? 'Sim' : 'Não'}</b>
              </td>
              <td className="w-100">
                <small>Data de expiração: </small>
                <br />
                <b>
                  {license.expirationDate
                    ? moment(license.expirationDate).format('L')
                    : '-'}
                </b>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Boolean(licences?.total) && (
        <p className="text-end">{licences?.total} licença(s) encontrado(s)</p>
      )}
      {licences && (
        <PaginationNav
          onChange={handlePageChange}
          totalPages={licences.totalPages}
          currentPage={licences.page}
        />
      )}
    </div>
  );
};

export default AdminLicensesListPage;
