import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from 'reactstrap';
import logo1 from 'assets/images/logo1.svg';
import Icon from 'components/Icon';
import { NavLink as NavLinkRouter } from 'react-router-dom';
import SessionContext from 'contexts/session';
import AlertPopups from 'components/AlertPopups';
import classnames from 'classnames';
import MediaUploadProvider from 'contexts/mediaUpload';
import NotificationButton from 'components/NotificationButton';

type MenuProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
  routes: { [key: string]: any };
};

const Menu = ({ isOpen, toggleSidebar, routes }: MenuProps) => {
  const [dropdownOpenTV, setdropdownOpenTV] = useState(false);
  const toggleMenuTV = () => setdropdownOpenTV((prevState) => !prevState);

  const [dropdownOpenUser, setdropdownOpenUser] = useState(false);
  const toggleMenuUser = () => setdropdownOpenUser((prevState) => !prevState);

  useEffect(() => {
    if (!isOpen) {
      setdropdownOpenTV(false);
      setdropdownOpenUser(false);
    }
  }, [isOpen]);

  return (
    <Nav
      className="d-flex justify-content-between align-items-stretch w-100"
      navbar
    >
      <NavItem>
        <NavLink className="text-dark" to="/" tag={NavLinkRouter}>
          <Icon className="me-3" icon="graph-up" />

          <span>Dashboard</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink onClick={toggleMenuTV} role="button">
          <button
            className="bg-transparent border-0 p-0"
            onClick={toggleSidebar}
          >
            <Icon className="me-3" icon="tv" />
          </button>
          <span>TV Coorporativa</span>
        </NavLink>
        <Collapse
          isOpen={dropdownOpenTV}
          className="bg-light py-2 border"
          data-bs-popper="static"
        >
          <NavItem tag="span">
            <NavLink
              role="button"
              tag={NavLinkRouter}
              to={routes.CAMPAIGN.LIST}
            >
              <span>Campanhas</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              role="button"
              tag={NavLinkRouter}
              to={routes.PLAYLIST.LIST}
            >
              <span>Playlists</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              role="button"
              tag={NavLinkRouter}
              to={routes.MEDIA_MANAGER.LIST}
            >
              <span>Mídias</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              role="button"
              tag={NavLinkRouter}
              to={routes.DEVICE_GROUP.LIST}
            >
              <span>Grupos de Dispositivos</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink role="button" tag={NavLinkRouter} to={routes.DEVICE.LIST}>
              <span>Dispositivos</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink role="button" tag={NavLinkRouter} to={routes.NEWS.LIST}>
              <span>Notícias Internas</span>
            </NavLink>
          </NavItem>
        </Collapse>
      </NavItem>
      <NavItem>
        <NavLink tag={NavLinkRouter} to={routes.CUSTOMER_COMPANIES.LIST}>
          <Icon className="me-3" icon="buildings" />
          <span>Empresas</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink onClick={toggleMenuUser} role="button">
          <button
            className="bg-transparent border-0 p-0"
            onClick={toggleSidebar}
          >
            <Icon className="me-3" icon="people" />
          </button>
          <span>Usuários</span>
        </NavLink>
        <Collapse
          isOpen={dropdownOpenUser}
          className="bg-light py-2 border"
          data-bs-popper="static"
        >
          <NavItem tag="span">
            <NavLink
              role="button"
              tag={NavLinkRouter}
              to={routes.CUSTOMER_USERS.LIST}
            >
              <span>Usuários</span>
            </NavLink>
          </NavItem>
          <NavItem tag="span">
            <NavLink
              role="button"
              tag={NavLinkRouter}
              to={routes.ACCESS_POLICIES.LIST}
            >
              <span>Políticas de acesso</span>
            </NavLink>
          </NavItem>
        </Collapse>
      </NavItem>
    </Nav>
  );
};

type SidebarProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
  routes: { [key: string]: any };
};

const Sidebar = ({ isOpen, toggleSidebar, routes }: SidebarProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      return window.innerWidth <= 576;
    };

    const handleResize = () => {
      setIsMobile(checkIsMobile());
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Col
      className={classnames(
        'bg-white',
        'z-2',
        'border border-top-0 border-bottom-1 border-gray-200',
        'pt-4',
        'pe-0',
        'ps-0',
        'app-sidebar',
        !isMobile
          ? isOpen
            ? 'opened'
            : 'closed'
          : isOpen
          ? 'closed-mobile'
          : 'opened'
      )}
    >
      <Menu
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        routes={routes}
        data-bs-popper="static"
      />
    </Col>
  );
};

type LayoutProps = PropsWithChildren<{
  routes: { [key: string]: any };
}>;
const DashboardLayout = ({ children, routes }: LayoutProps) => {
  const { user, logout } = useContext(SessionContext);

  const onLogoutHandler = useCallback(() => {
    logout();
  }, [logout]);

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleMenu = () => setDropdownOpen((prevState) => !prevState);

  return (
    <MediaUploadProvider>
      <Navbar
        color="white"
        fixed="top"
        container="fluid"
        light
        className="sticky-top d-flex justify-content-between border border-top-0 border-bottom-1 border-gray-200"
      >
        <NavbarToggler className="p-0 border-0" onClick={toggle} />

        <NavbarBrand>
          <img src={logo1} height="30px" className="mx-auto" alt="logo" />
        </NavbarBrand>
        <NavbarBrand className="d-flex align-items-center">
          <NotificationButton />
          {user && (
            <span className="d-none d-xs-none d-sm-none d-md-block fs-6">
              Olá, {user.firstName}!
            </span>
          )}
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleMenu}
            direction="down"
            data-bs-popper="static"
          >
            <DropdownToggle
              caret
              color="none"
              className="d-flex align-items-center"
            >
              {user?.picture ? (
                <img
                  width="20px"
                  height="20px"
                  src={user.picture}
                  alt="User"
                  className="rounded-circle mx-auto"
                />
              ) : (
                <Icon icon="person-circle" className="text-secondary" />
              )}
            </DropdownToggle>
            <DropdownMenu data-bs-popper="static">
              <DropdownItem to={routes.USER_PROFILE.EDIT} tag={NavLinkRouter}>
                Sua Conta
              </DropdownItem>
              <DropdownItem onClick={onLogoutHandler}>
                Sair do Sistema
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarBrand>
      </Navbar>
      <Container fluid>
        <div className="row flex-nowrap">
          <Sidebar
            isOpen={!isOpen}
            toggleSidebar={toggle}
            routes={routes}
            data-bs-popper="static"
          />
          <Col className="w-100 p-0 bg-light">
            <main
              role="main"
              className="h-100 px-2 pt-2 pb-0 overflow-x-hidden overflow-y-scroll "
            >
              <Container
                fluid
                className="p-3 bg-white border border-light-subtle h-100"
              >
                {children}
              </Container>
            </main>
          </Col>
        </div>
      </Container>
      <AlertPopups />
    </MediaUploadProvider>
  );
};

export default DashboardLayout;
