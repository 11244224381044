import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

type MediaCampaignPreviewProps = {
  medias: any[];
};

const second = 1000;

const MediaCampaignPreview = ({ medias }: MediaCampaignPreviewProps) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const timeoutRef = useRef<any>(null);

  const handleNextMedia = useCallback(() => {
    const nextIndex = (currentIndex + 1) % medias.length;
    setIsLoaded(false);
    setCurrentIndex(nextIndex);
  }, [currentIndex, medias.length]);

  useEffect(() => {
    const currentMedia = medias[currentIndex];
    const isImage = currentMedia?.mediaFile?.mineType?.startsWith('image');

    if (isImage) {
      const duration = currentMedia?.duration * second;
      timeoutRef.current = setTimeout(handleNextMedia, duration);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentIndex, medias, handleNextMedia]);

  useEffect(() => {
    setIsLoaded(false);
  }, [currentIndex]);

  if (!medias.length) return null;

  const currentMedia = medias[currentIndex];
  const isImage = currentMedia?.mediaFile?.mineType?.startsWith('image');

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return isImage ? (
    <>
      <img
        src={currentMedia?.editedFile}
        alt="Campaign Preview"
        className={classNames('media-content', { loaded: isLoaded })}
        onLoad={handleLoad}
      />
    </>
  ) : (
    <video
      data-testid="video"
      src={currentMedia?.editedFile}
      onEnded={handleNextMedia}
      onCanPlayThrough={handleLoad}
      autoPlay
      className={classNames('media-content', { loaded: isLoaded })}
    />
  );
};

export default MediaCampaignPreview;
