import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import Device from 'models/Device';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import SwitchField from 'components/SwitchField';
import AsyncTypeaheadField, { Option } from 'components/AsyncTypeaheadField';
import RadioChoicesFiled from 'components/RadioChoicesField';
import ScreenOrientation from 'enum/ScreenOrientation';
import DeviceGroup from 'models/DeviceGroup';
import Pagination from 'models/Pagination';

type FormValue = {
  name: string;
  active: boolean;
  group: Option | null;
  screenOrientation: ScreenOrientation;
  local: string;
};

const yupSchema = yup.object({
  name: yup.string().required('O nome é obrigatório.'),
  group: yup.object().required('O grupo é obrigatório.'),
  screenOrientation: yup
    .mixed<ScreenOrientation>()
    .oneOf(Object.values(ScreenOrientation))
    .required(),
  local: yup.string().required('O local é obrigatório.'),
  active: yup.boolean()
});

const DeviceFormPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data, isLoading: isFetching } = useApiGet<Device>(`/device`, {
    id: params.id,
    enabled: !!params.id
  });
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<Device>(
    '/device'
  );
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<Device>(
    '/device',
    params.id
  );

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/device',
    params.id
  );

  const {
    data: deviceGroupData,
    refetch: deviceGroupRefetch,
    isLoading: isLoadingDeviceGroup
  } = useApiGet<Pagination<DeviceGroup>>('/device/group');

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.UPDATE);
    } else {
      return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.CREATE);
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.USERS]?.includes(TypeAction.DELETE);
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const device = params.id ? data : undefined;

  const formMethods = useForm<FormValue>({
    resolver: yupResolver(yupSchema as yup.ObjectSchema<FormValue>),
    defaultValues: {
      name: '',
      active: true,
      group: null,
      local: '',
      screenOrientation: ScreenOrientation.LANDSCAPE
    }
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: FormValue) => {
      if (!userCanPerformAction) return;
      const payload = {
        group: data.group?.value!,
        name: data.name,
        active: data.active,
        screenOrientation: data.screenOrientation,
        local: data.local
      } as any;
      mutate(payload, {
        onError,
        onSuccess: () => {
          addToast({
            color: 'success',
            icon: 'check',
            message: 'Dispositivo salvo com sucesso'
          });
          navigate('/device');
        }
      });
    },
    [userCanPerformAction, mutate, onError, addToast, navigate]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() ||
            'Erro ao excluir o dispositivo'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Dispositivo excluído com sucesso'
        });
        navigate('/device');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const deviceGroupOptions = useMemo(() => {
    const data = deviceGroupData?.items || [];
    return data.map(
      (deviceGroup) =>
        ({
          label: deviceGroup.name,
          value: deviceGroup.id
        } as any)
    );
  }, [deviceGroupData]);

  const typeOptionsOrientation = [
    {
      value: ScreenOrientation.PORTRAIT,
      label: 'Retrato'
    },
    {
      value: ScreenOrientation.LANDSCAPE,
      label: 'Paisagem'
    }
  ];

  useEffect(() => {
    if (device) {
      formMethods.reset({
        name: device.name,
        local: device.local,
        active: device.active,
        screenOrientation: device.screenOrientation,
        group: device.group
          ? {
              label: device.group?.name,
              value: device.group?.id
            }
          : null
      } as any);
    }
  }, [device, formMethods]);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <span className="h5 fw-bolder mb-0">Dispositivos</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <Row xs="2" className="d-flex align-items-center">
              <Col>
                <TextField
                  name="name"
                  label="Nome do dispositivo:"
                  placeholder="Nome do dispositivo"
                  disabled={!userCanPerformAction}
                  defaultValue={device?.name}
                />
              </Col>
              <Col>
                <AsyncTypeaheadField
                  isLoading={isLoadingDeviceGroup}
                  name="group"
                  label="Grupo:"
                  options={deviceGroupOptions}
                  onSearch={(search: string) =>
                    deviceGroupRefetch({
                      params: { search }
                    } as any)
                  }
                  disabled={!userCanPerformAction}
                  multiple={false}
                />
              </Col>
              <Col>
                <TextField
                  name="local"
                  label="Local:"
                  placeholder="Local"
                  disabled={!userCanPerformAction}
                  defaultValue={device?.local}
                />
              </Col>
              <Col>
                <RadioChoicesFiled
                  label="Orientação:"
                  name="screenOrientation"
                  options={typeOptionsOrientation}
                  classNameRadio="d-flex"
                />
              </Col>
              <Col>
                <SwitchField
                  name="active"
                  label="Ativo"
                  disabled={!userCanPerformAction}
                  checked={device?.active}
                />
              </Col>
            </Row>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Deseja realmente excluir o dispositivo? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};
export default DeviceFormPage;
