import React from 'react';
import Modal from './Modal';
import AdminNotification from 'models/AdminNotification';

type ModaAdminImagelNotificationProps = {
  isOpen: boolean;
  toggle: () => void;
  adminNotification: AdminNotification;
};

const ModalAdminImageNotification = ({
  isOpen,
  toggle,
  adminNotification
}: ModaAdminImagelNotificationProps) => {
  const { title, image } = adminNotification;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={title}
      primaryButtonLabel="Fechar"
      primaryButtonAction={toggle}
    >
      <div className="modal-body">
        {image && <img src={image} className="w-100 mt-3" alt="" />}
      </div>
    </Modal>
  );
};

export default ModalAdminImageNotification;
