import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Form from 'components/Form';
import { useForm, useWatch } from 'react-hook-form';
import TextField from 'components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from 'hooks/useApi';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import { useToast } from 'hooks/useToast';
import { Loader } from 'react-bootstrap-typeahead';
import Icon from 'components/Icon';
import CustomerCompanies from 'models/CustomerCompanies';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import CustomerUser from 'models/CustomerUser';
import AsyncTypeaheadField from 'components/AsyncTypeaheadField';
import SwitchField from 'components/SwitchField';
import Pagination from 'models/Pagination';
import classNames from 'classnames';

const yupSchema = yup
  .object({
    name: yup.string().required('O nome fantasia é obrigatório.'),
    email: yup.string().email().required(),
    razaoSocial: yup.string().required('A razão social é obrigatória.'),
    addresses: yup.array().of(
      yup.object().shape({
        mainAddress: yup.boolean(),
        billingAddress: yup
          .boolean()
          .when('mainAddress', (mainAddress, schema) => {
            if (mainAddress) {
              return schema.default(true);
            } else if (!mainAddress) {
              return schema.default(false);
            }
            return schema.default(false);
          })
      })
    ),
    users: yup.array(),
    phone: yup.string(),
    cellPhone: yup.string(),
    cnpj: yup.string().required('O CNPJ é obrigatório.'),
    stateRegistration: yup.string(),
    municipalRegistration: yup.string()
  })
  .required();

const CustomerCompaniesFormPage = () => {
  const params = useParams();
  const user = useUser();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, isLoading: isFetching } = useApiGet<CustomerCompanies>(
    `/customer/companies`,
    { id: params.id, enabled: !!params.id }
  );
  const { mutate: mutateCreate, isPending: isCreating } = useApiPost<
    CustomerCompanies
  >('/customer/companies');
  const { mutate: mutateUpdate, isPending: isUpdating } = useApiPut<
    CustomerCompanies
  >('/customer/companies', params.id);

  const { mutate: mutateDelete, isPending: isDeleting } = useApiDelete(
    '/customer/companies',
    params.id
  );

  const {
    data: customerUsersData,
    refetch: customerUsersRefetch,
    isLoading: isLoadingCustomerUsers
  } = useApiGet<Pagination<CustomerUser>>('/customer/users');

  const mutate = params.id ? mutateUpdate : mutateCreate;

  const userCanPerformAction = useMemo(() => {
    if (params.id) {
      return user?.resources?.[TypeResource.COMPANIES]?.includes(
        TypeAction.UPDATE
      );
    } else {
      return user?.resources?.[TypeResource.COMPANIES]?.includes(
        TypeAction.CREATE
      );
    }
  }, [user, params.id]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.COMPANIES]?.includes(
      TypeAction.DELETE
    );
  }, [user]);

  const isSaving = isCreating || isUpdating;

  const customerCompanies = params.id ? data : undefined;

  const formMethods = useForm({
    resolver: yupResolver(yupSchema)
  });

  const switchValue = useWatch({
    control: formMethods.control,
    name: 'addresses.0.mainAddress',
    defaultValue: false
  });

  const onError = useFieldSetErrors(formMethods.setError);

  const handleOnSubmit = useCallback(
    (data: CustomerCompanies) => {
      if (!userCanPerformAction) return;
      const dataUsers = data?.users?.map((user: any) => user.value);
      const dataAddresses = data?.addresses?.map(
        (address: any, index: number) => {
          const addressPayloadMain: any = {
            street: address?.street,
            number: address?.number,
            complement: address?.complement,
            district: address?.district,
            city: address?.city,
            state: address?.state,
            zipCode: address?.zipCode,
            mainAddress: index === 0,
            billingAddress:
              (index === 0 && switchValue) || (index === 1 && !switchValue)
          };
          return addressPayloadMain;
        }
      );
      mutate(
        { ...data, users: dataUsers, addresses: dataAddresses },
        {
          onError,
          onSuccess: () => {
            addToast({
              color: 'success',
              icon: 'check',
              message: 'Empresa salva com sucesso'
            });
            navigate('/customer/companies');
          }
        }
      );
    },
    [addToast, mutate, navigate, onError, userCanPerformAction, switchValue]
  );

  const handleOnDelete = useCallback(() => {
    if (!params.id || !userCanDelete) return;
    mutateDelete(null as any, {
      onError: (response: any) => {
        addToast({
          color: 'danger',
          icon: 'exclamation',
          message:
            response.errors?.details?.toString() || 'Erro ao excluir empresa'
        });
      },
      onSuccess: () => {
        addToast({
          color: 'success',
          icon: 'check',
          message: 'Empresa excluída com sucesso'
        });
        navigate('/customer/companies');
      }
    });
  }, [addToast, mutateDelete, navigate, params.id, userCanDelete]);

  const customerUsersOptions = useMemo(() => {
    const data = customerUsersData?.items || [];
    return data.map((user) => ({
      label: user.firstName + ' ' + user.lastName,
      value: user.id
    }));
  }, [customerUsersData]);

  useEffect(() => {
    if (customerCompanies) {
      formMethods.reset({
        name: customerCompanies.name,
        email: customerCompanies.email,
        razaoSocial: customerCompanies.razaoSocial,
        phone: customerCompanies.phone,
        cellPhone: customerCompanies.cellPhone,
        cnpj: customerCompanies.cnpj,
        stateRegistration: customerCompanies.stateRegistration,
        municipalRegistration: customerCompanies.municipalRegistration,
        users: customerCompanies.users?.map((user) => ({
          label: user.firstName + ' ' + user.lastName,
          value: user.id
        })),
        addresses: customerCompanies.addresses?.map((address) => ({
          ...address,
          mainAddress: address.mainAddress || false,
          billingAddress: address.billingAddress || false
        }))
      } as any);
    }
  }, [customerCompanies, formMethods]);

  return (
    <>
      <div className="bg-white p-2">
        <Form onSubmit={handleOnSubmit} {...formMethods}>
          <Row className="d-flex align-items-center bg-white mb-2">
            <Col className="bg-white">
              <p className="mt-2 d-flex align-items-center">
                <Link
                  to={'/customer/companies'}
                  className="btn btn-link text-dark text-decoration-none me-2"
                >
                  <Icon icon="chevron-left" />
                </Link>
                <span className="h5 fw-bolder mb-0">Empresas</span>
              </p>
            </Col>
            <Col className="text-end">
              {params.id && userCanDelete && (
                <Button
                  type="button"
                  color="danger"
                  loading={isDeleting}
                  icon="trash"
                  className="me-2"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Excluir
                </Button>
              )}
              {userCanPerformAction && (
                <Button color="primary" icon="floppy" loading={isSaving}>
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          {isFetching ? (
            <Loader />
          ) : (
            <Row>
              <Col className="col-sm-12 col-md-6 offset-md-3">
                <p className="fs-5">Dados Gerais</p>
                <TextField
                  name="name"
                  label="Nome Fanstasia:"
                  placeholder="Nome Fantasia"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.name}
                />
                <TextField
                  name="razaoSocial"
                  label="Razão Social:"
                  placeholder="Razão Social"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.razaoSocial}
                />
                <TextField
                  name="email"
                  label="Email:"
                  placeholder="Email"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.email}
                />
                <TextField
                  name="phone"
                  label="Telefone:"
                  placeholder="Telefone"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.phone}
                />
                <TextField
                  name="cellPhone"
                  label="Celular:"
                  placeholder="Celular"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.cellPhone}
                />
                <TextField
                  name="cnpj"
                  label="CNPJ:"
                  placeholder="CNPJ"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.cnpj}
                />
                <TextField
                  name="stateRegistration"
                  label="Inscrição Estadual:"
                  placeholder="Inscrição Estadual"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.stateRegistration}
                />
                <TextField
                  name="municipalRegistration"
                  label="Inscrição Municipal:"
                  placeholder="Inscrição Municipal"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.municipalRegistration}
                />
                <p className="fs-5">Dados de Endereço</p>
                <TextField
                  name="addresses.0.street"
                  label="Rua:"
                  placeholder="Rua"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.addresses?.[0]?.street}
                />
                <TextField
                  name="addresses.0.number"
                  label="Número:"
                  placeholder="Número"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.addresses?.[0]?.number}
                />
                <TextField
                  name="addresses.0.complement"
                  label="Complemento:"
                  placeholder="Complemento"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.addresses?.[0]?.complement}
                />
                <TextField
                  name="addresses.0.district"
                  label="Bairro:"
                  placeholder="Bairro"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.addresses?.[0]?.district}
                />
                <TextField
                  name="addresses.0.city"
                  label="Cidade:"
                  placeholder="Cidade"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.addresses?.[0]?.city}
                />
                <TextField
                  name="addresses.0.state"
                  label="Estado:"
                  placeholder="Estado"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.addresses?.[0]?.state}
                />
                <TextField
                  name="addresses.0.zipCode"
                  label="CEP:"
                  placeholder="CEP"
                  disabled={!userCanPerformAction}
                  defaultValue={customerCompanies?.addresses?.[0]?.zipCode}
                />
                <div className="mb-3">
                  <SwitchField
                    name="addresses.0.mainAddress"
                    label="Endereço de cobrança é o mesmo que o principal"
                    disabled={!userCanPerformAction}
                  />
                </div>
                <div
                  className={classNames('mb-3', {
                    'd-none': switchValue
                  })}
                >
                  <p className="fs-5">Dados do endereço de cobrança</p>
                  <TextField
                    name="addresses.1.street"
                    label="Rua:"
                    placeholder="Rua"
                    disabled={!userCanPerformAction}
                    defaultValue={customerCompanies?.addresses?.[1]?.street}
                  />
                  <TextField
                    name="addresses.1.number"
                    label="Número:"
                    placeholder="Número"
                    disabled={!userCanPerformAction}
                    defaultValue={customerCompanies?.addresses?.[1]?.number}
                  />
                  <TextField
                    name="addresses.1.complement"
                    label="Complemento:"
                    placeholder="Complemento"
                    disabled={!userCanPerformAction}
                    defaultValue={customerCompanies?.addresses?.[1]?.complement}
                  />
                  <TextField
                    name="addresses.1.district"
                    label="Bairro:"
                    placeholder="Bairro"
                    disabled={!userCanPerformAction}
                    defaultValue={customerCompanies?.addresses?.[1]?.district}
                  />
                  <TextField
                    name="addresses.1.city"
                    label="Cidade:"
                    placeholder="Cidade"
                    disabled={!userCanPerformAction}
                    defaultValue={customerCompanies?.addresses?.[1]?.city}
                  />
                  <TextField
                    name="addresses.1.state"
                    label="Estado:"
                    placeholder="Estado"
                    disabled={!userCanPerformAction}
                    defaultValue={customerCompanies?.addresses?.[1]?.state}
                  />
                  <TextField
                    name="addresses.1.zipCode"
                    label="CEP:"
                    placeholder="CEP"
                    disabled={!userCanPerformAction}
                    defaultValue={customerCompanies?.addresses?.[1]?.zipCode}
                  />
                </div>
                <p className="fs-5">Usuários</p>
                <div className="mb-3">
                  <AsyncTypeaheadField
                    isLoading={isLoadingCustomerUsers}
                    name="users"
                    label="Usuários:"
                    options={customerUsersOptions}
                    onSearch={(search: string) =>
                      customerUsersRefetch({
                        params: { search }
                      } as any)
                    }
                    disabled={!userCanPerformAction}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </div>
      <Modal
        primaryButtonAction={handleOnDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir a empresa? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
      </Modal>
    </>
  );
};

export default CustomerCompaniesFormPage;
