import { User } from 'models/User';
import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { useApiDelete, useApiGet } from 'hooks/useApi';
import PageLoader from 'components/PageLoader';

type SessionContextType = {
  user?: User;
  setUser: (user?: User) => void;
  logout: () => void;
  userChecking?: boolean;
};

export const SessionContext = React.createContext<SessionContextType>({
  setUser: () => {},
  logout: () => {}
});

export default SessionContext;

export function SessionProvider({ children }: PropsWithChildren<any>) {
  const [userChecked, setUserChecked] = React.useState(false);
  const [user, setUser] = React.useState<User>();
  const { mutate: mutateLogout } = useApiDelete('/auth/logout');
  const { data, isFetched } = useApiGet('/auth/user', {
    enabled: !user && !userChecked,
    retry: false
  });

  const logout = useCallback(() => {
    setUser(undefined);
    mutateLogout();
  }, [mutateLogout]);

  useEffect(() => {
    if (isFetched && !userChecked) {
      setUserChecked(true);
      if (data && !user) {
        setUser(data);
      }
    }
  }, [data, user, isFetched, userChecked]);

  return (
    <SessionContext.Provider
      value={{ user, setUser, logout, userChecking: !userChecked }}
    >
      {!userChecked ? <PageLoader /> : children}
    </SessionContext.Provider>
  );
}
