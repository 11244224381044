import {
  Card,
  CardFooter,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import Icon from 'components/Icon';
import moment from 'moment';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import MediaFile from 'models/MediaFile';
import Modal from 'components/Modal';
import useUser from 'hooks/useUser';
import { useApiDelete } from 'hooks/useApi';
import useFieldSetErrors from 'hooks/useFieldSetErrors';
import TypeResource from 'enum/TypeResource';
import TypeAction from 'enum/TypeAction';
// @ts-ignore
import { useDrag } from 'react-dnd';

type ModalItemCardType = {
  mediaFile: MediaFile;
  onEdit?: (mediaFile: MediaFile) => void;
  reFetchMedias?: () => void;
  onClick?: (mediaFile: MediaFile) => void;
};
const MediaItemCard = ({
  mediaFile,
  onEdit,
  onClick,
  reFetchMedias
}: ModalItemCardType) => {
  const icon = useMemo(() => {
    if (mediaFile.isFolder) return 'folder';
    const iconFiletype = mediaFile.mineType?.split('/')[1];
    if (!iconFiletype) return 'file-earmark';
    switch (iconFiletype) {
      case 'jpeg':
        return 'filetype-jpg';
      case 'bmp':
      case 'svg+xml':
        return 'file-earmark-image';
      case 'x-msvideo':
      case 'quicktime':
      case 'x-matroska':
      case 'mpeg':
        return 'file-play';
      default:
        return `filetype-${iconFiletype}`;
    }
  }, [mediaFile]);
  const [showOptions, setShowOptions] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );
  const [errors, setErrors] = React.useState<string>();
  const user = useUser();
  const { mutate, isPending } = useApiDelete(
    '/media-manager/medias',
    mediaFile.id
  );
  const onError = useFieldSetErrors((_: string, error: any) =>
    setErrors(error.message)
  );

  const handleMediaClick = useCallback(() => {
    onClick?.(mediaFile);
  }, [mediaFile, onClick]);

  const handleDeleteConfirmation = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowDeleteConfirmation(true);
  };
  const handleDelete = useCallback(() => {
    mutate(undefined, {
      onError,
      onSuccess: () => {
        setShowDeleteConfirmation(false);
        reFetchMedias?.();
      }
    });
  }, [mutate, onError, reFetchMedias]);

  const userCanDelete = useMemo(() => {
    return user?.resources?.[TypeResource.MEDIAS]?.includes(TypeAction.DELETE);
  }, [user]);

  const userCanEdit = useMemo(() => {
    return user?.resources?.[TypeResource.MEDIAS]?.includes(TypeAction.UPDATE);
  }, [user]);

  const isVideo = useMemo(() => {
    return mediaFile.mineType?.startsWith('video');
  }, [mediaFile]);

  const handleEdit = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onEdit?.(mediaFile);
    },
    [mediaFile, onEdit]
  );
  const [collected, drag, dragPreview] = useDrag<MediaFile, MediaFile, any>(
    () => ({
      type: 'MediaItem',
      item: mediaFile
    })
  );
  const ref = collected.isDragging ? dragPreview : drag;
  return (
    <div className="h-100" ref={ref} {...collected}>
      <Card className="h-100">
        <CardHeader className="p-1 d-flex flex-nowrap justify-content-between align-items-center">
          <p className="w-100 text-nowrap text-truncate mb-0 small">
            <Icon icon={icon} className="me-1" />
            <small>{mediaFile.name}</small>
          </p>
        </CardHeader>
        {mediaFile.isFolder ? (
          <div
            className="img-thumbnail mx-auto w-100 my-auto overflow-hidden"
            role="button"
            onClick={handleMediaClick}
          >
            <div className="media-icon folder d-flex justify-content-center align-items-center w-100 h-100">
              <Icon icon="folder-fill" />
            </div>
          </div>
        ) : (
          <div
            className="img-thumbnail mx-auto w-100 my-auto overflow-hidden"
            role="button"
            onClick={handleMediaClick}
          >
            {isVideo && (
              <div className="media-icon position-absolute d-flex justify-content-center align-items-center w-100 h-100">
                <Icon icon="play-fill" />
              </div>
            )}
            <img
              alt={mediaFile.name}
              src={mediaFile.thumbnail}
              className="m-auto w-100"
            />
          </div>
        )}
        <CardFooter className="p-1 d-flex justify-content-between align-items-center">
          <p className="w-100 text-nowrap text-truncate mb-0 small">
            <small>
              Enviado {moment(mediaFile.createdAt).calendar()} por{' '}
              {mediaFile.createdBy?.firstName}
            </small>
          </p>
          {(userCanEdit || userCanDelete) && (
            <Dropdown
              isOpen={showOptions}
              toggle={() => setShowOptions((x) => !x)}
              direction="down"
            >
              <DropdownToggle
                color="ligth"
                className="text-decoration-none btn-link btn text-dark link-offset-3-hover"
              >
                <Icon icon="three-dots-vertical" />
              </DropdownToggle>
              <DropdownMenu>
                {userCanEdit && (
                  <DropdownItem onClick={handleEdit}>
                    <Icon icon="pencil" className="me-2" />
                    Renomear
                  </DropdownItem>
                )}
                {userCanDelete && (
                  <DropdownItem onClick={handleDeleteConfirmation}>
                    <Icon icon="trash" className="me-2" />
                    Remover
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </CardFooter>
      </Card>
      <Modal
        isLoading={isPending}
        primaryButtonAction={handleDelete}
        primaryButtonLabel="Excluir"
        title="Atenção!"
        isOpen={showDeleteConfirmation}
        showCloseButton={false}
        toggle={() => setShowDeleteConfirmation((x) => !x)}
        secondaryButtonAction={() => setShowDeleteConfirmation(false)}
        secondaryButtonLabel="Cancelar"
      >
        <p>
          Desaja realmente excluir essa{' '}
          {mediaFile?.isFolder ? `pasta` : `mídia`}? <br />
          <small>Essa alteração não poderá ser revertida.</small>
        </p>
        {errors && <p className="text-danger">{errors}</p>}
      </Modal>
    </div>
  );
};

export default MediaItemCard;
