import React, { useCallback, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { InputProps } from 'reactstrap/types/lib/Input';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { isArray } from 'lodash';

export type Option = {
  label: string;
  value: string;
};

type MultipleSelectProps = {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onSearch?: (query: string) => void;
  valueDefault?: Option[];
  options?: Option[];
  multiple?: boolean;
} & InputProps;

const MultipleSelectField = ({
  name,
  label,
  placeholder,
  disabled,
  onSearch,
  isLoading,
  valueDefault,
  options,
  multiple = true,
  ...props
}: MultipleSelectProps) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    defaultValue: valueDefault || []
  });

  const [open, setOpen] = useState(false);

  const handleSearch = useCallback(
    (query: string) => {
      onSearch?.(query);
    },
    [onSearch]
  );

  const handleChange = useCallback(
    (selectedOptions: any[]) => {
      if (multiple) {
        onChange(selectedOptions);
      } else {
        onChange(selectedOptions?.[0] || null);
      }
      setOpen(false);
    },
    [onChange, multiple]
  );

  const selected = useMemo(() => {
    if (multiple) {
      return value;
    }
    return value ? (isArray(value) ? value : [value]) : [];
  }, [value, multiple]);

  return (
    <FormGroup>
      {label && (
        <Label htmlFor={name} className="w-100 mt-1">
          {label}
          <AsyncTypeahead
            id={name}
            labelKey="label"
            selected={selected}
            onChange={handleChange}
            isInvalid={!!error}
            placeholder={placeholder}
            options={options || []}
            onSearch={handleSearch}
            isLoading={isLoading}
            disabled={disabled}
            multiple={multiple}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            onMenuToggle={setOpen}
            open={open}
          />
          {error && <FormFeedback>{error.message}</FormFeedback>}
        </Label>
      )}
      {!label && (
        <AsyncTypeahead
          id={name}
          labelKey="label"
          selected={selected}
          onChange={handleChange}
          isInvalid={!!error}
          placeholder={placeholder}
          options={options || []}
          onSearch={handleSearch}
          isLoading={isLoading}
          disabled={disabled}
          multiple={multiple}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          onMenuToggle={setOpen}
          open={open}
        />
      )}
    </FormGroup>
  );
};

export default MultipleSelectField;
